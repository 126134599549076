export const homeStudyContent = {
    title: `A Homestudy – What is It?`,
    contents: [
        {
            key: 'content1',
            value: `All 50 states require a homestudy document to be completed before a couple or individual can adopt a 
            child or sibling group. A homestudy has specific guidelines that must be met to be completed properly. Though 
            the format can vary from state to state and across different agencies, most generally have two different parts – 
            training process and information gathering.`
        },
        {
            key: 'content2',
            value: `The training process is the first part and deals mainly with preparation and education about adoption. 
            This can include such topics as preparing families for adoption, helping children adjust to the new type of family 
            and it’s make-up, attaching and bonding with your adopted children, strategies and challenges with parenting, and 
            how to explain adoption to your current children.`
        },
        {
            key: 'content3',
            value: `This training can take between 10 and 20 hours and is usually done during a series of meetings with other 
            parents interesting in adopting. This training gives you a good idea of the adoption process and helps you be more 
            prepared to adjust to having newly adopted child in your home. The added bonus of these meetings is getting to meet 
            other adopting parents, which allows you to make friendship connections with other parents going through the same process 
            as you. This creates a friendship support system that can help you get through some of the challenges and stress of 
            parenting and adoption.`
        },
        {
            key: 'content4',
            value: `The next part of the homestudy is based on information gathering. This also is usually done through a series of 
            meetings (both alone and with both parents) with a Case Worker. One of these sessions will be in your home, which allows
             the Case Worker to see the environment the adopted child will soon become a part of. If you have other people currently
              in the home – like children or aged relatives – they will be interviewed as well. Part of this process will include 
              providing documents like birth certificates, marriage licenses, health records, criminal background check reports, 
              personal references, and a child abuse clearance, which will be done through the agency.`
        },
        {
            key: 'content5',
            value: `Many agencies will ask you to write-up information about yourself, your family, and even life experiences. 
            Another part of this process is talking to you Case Worker about the types of children you feel you can best raise as a 
            parent and will become a part of your family and home.`
        },
        {
            key: 'content6',
            value: `This information is combined into the homestudy document. Once this process is completed, you will be notified 
            by your Case Worker and the adoption agency that you have been approved to adopt a child. The next step in the process 
            will be to find the right match between your family and the adoptive child.`
        }
    ]
}