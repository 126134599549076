import { createSelector } from "reselect";

const applyModalState = (state) => state.careers.applyModalState;
const applyPayload = (state) => state.careers.applyPayload;
const isCaptchaVerified = (state) => state.careers.isCaptchaVerified;
const isSubmitting = (state) => state.careers.isSubmitting;

export const getModalState = createSelector([applyModalState], (data) => data);
export const getApplyPayload = createSelector([applyPayload], (data) => data);
export const getIsCaptchaVerified = createSelector([isCaptchaVerified], (data) => data);
export const getIsSubmitting = createSelector([isSubmitting], (data) => data);