export const careers = [
    {
        key: `job1`,
        title: `Case Manager`,
        descTitle: `Job Description`,
        desc: `We are growing and need a Case Manager who will work directly with children and their foster 
        families, including visits to the home, managing case loads, and maintain an understanding of their daily lives. 
        The position requires a significant amount of paperwork and the individual will be required to maintain the family
        and child files. Excellent time management and organizational skills are a must. The individual will also need 
        impeccable communication skills. A Bachelor's degree in a Human Services field is required.`,
        skillDesc: `The individual also must have the following:`,
        skillSet: [
            {
                key: `skill1`,
                value: `Excellent oral and written communication skills`
            },
            {
                key: `skill2`,
                value: `Well organized and successful with Time Management`
            },
            {
                key: `skill3`,
                value: `Able to motivate and engage staff and clients`
            },
            {
                key: `skill4`,
                value: `Able to problem solve quickly and confidently`
            },
            {
                key: `skill5`,
                value: `Must work well within a time frames`
            },
            {
                key: `skill6`,
                value: `Ability to self-start with little direction`
            },
            {
                key: `skill7`,
                value: `Able to handle and function under stress`
            },
            {
                key: `skill8`,
                value: `Understanding the hours needed to complete job`
            }
        ],
        salaryTitle: `Salary`,
        salary: `TBD with experience`,
        locationTitle: `Location`,
        location: `San Antonio, TX`,
        expTitle: `Experience Required`,
        exp: `A Minimum of 1 Year in Foster Care Case Management`,
        travelTitle: `Travel Requirements`,
        travelReq: `Up to 50%`,
        educationTitle: `Education Required`,
        educationReq: `Bachelor's Degree in a Human Services field`,
        jobStatusTitle: `Job Status`,
        jobStatus: `Full Time 40+ Hours including On-Call Duties`
    }
]