export const applyContent = {
    desc: `Please provide us with some basic information.We will contact you with further details.Thank you.`,
    locations : [
        {
            key: `sa`,
            value: `San Antonio`
        },
        {
            key: `Austin`,
            value: `Austin`
        }
    ]
}

export const states = [
    "None" ,"Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Guam",
    "Hawai", "Idaho", "Illinois","Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
    "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana",
    "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", 
    "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Peurto Rico", "Rhode Island", 
    "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", 
    "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
]

export const infoOptions = [
    "", "Web", "Word of Mouth", "State Orientation", "Special event / Outreach", 
    "Conference", "Direct Canvas"
]