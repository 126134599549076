import React, { useRef, useState } from 'react';
import { getModalState, getIsCaptchaVerified, getApplyPayload } from './CareersSelectors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Grid, TextField, Grow } from '@material-ui/core';
import { captchaVerify, handleApplyModal, submitJobApplication } from './CareersActions';
import { validateMailFormat } from '../../util/utilities';
import ReCAPTCHA from 'react-google-recaptcha';
import { site_key } from '../../util/captchaKeyConsts';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    formContainer: {
        color: '#1A355D',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '15px 0',
    },
    fileInput: {
        color: '#d04e53',
        padding: '5px 0',
    },
    fields: {
        minWidth: 'calc(100% - 20px)'
    },
    submitBtn: {
        color: 'white',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            backgroundColor: '#22477E',
            color: 'white',
        }
    },
    disabledBtn : {
        color: 'white',
        pointerEvents: 'none',
        cursor: 'not-allowed',
        backgroundColor: '#485f81',
        '&:hover': {
            color: 'white',
            backgroundColor: '#485f81',
        }
    }
  }));

const ApplyModal = () => {
    const errObjInitialState = {
        nameErr: null, mailErr: null, fileErr: null, fileExtErr : null, 
        validationErr: null, fileSizeErr: null, captchErr: null
    }

    const formRef = useRef(null);
    const classes = useStyles();
    const modalState = useSelector(getModalState);
    const applyPayloadInitState = useSelector(getApplyPayload);
    const isCaptchaVerified = useSelector(getIsCaptchaVerified);
    const dispatch = useDispatch();
    const [applyPayload, setApplyPayload] = useState(applyPayloadInitState);
    const [errs, setErrs ] = useState(errObjInitialState);

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleClose = () => {
        dispatch(handleApplyModal(false));
        setErrs({
            nameErr: null, mailErr: null, fileErr: null, fileExtErr : null, 
            validationErr: null, fileSizeErr: null, captchErr: null
        });
        setApplyPayload({
            name: '', email: '', phone: 0, selectedFile: undefined
        });
    };

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'name':
                setApplyPayload((prevState) => ({...prevState, name: evt.target.value}));
                setErrs({...errs, nameErr: false});
                break;
            case 'email':
                if(validateMailFormat(evt.target.value)){
                    setApplyPayload((prevState) => ({...prevState, email: evt.target.value}));
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
                }
                else{
                    setApplyPayload((prevState) => ({...prevState, email: ""}));
                    setErrs((prevState) => ({...prevState, validationErr: true, mailErr: false}));
                }
                break;  
            case 'phone':
                setApplyPayload((prevState) => ({...prevState, phone: evt.target.value}));
                break;
            default: 
        }
    }

    const handleFileSelection = (e) => {
        setErrs((prevState) => ({...prevState, fileErr: false}));
        const allowedExts = ["doc", "docx", "pdf", "odt", "txt", "rtf"];
        const hasFile = e.target.files && e.target.files.length > 0;
        const fileExt =  hasFile && e.target.files[0].name.split('.').pop().toString();
        const fileSize = hasFile && (e.target.files[0].size)/1024/1024;
        //check file extension type
        !allowedExts.includes(fileExt) ? setErrs((prevState) => ({...prevState,fileExtErr: true})) 
            : setErrs((prevState) => ({...prevState, fileExtErr: false}));
        //check file size
        fileSize > 5 ? setErrs((prevState) => ({...prevState, fileSizeErr: true}))
             : setErrs((prevState) => ({...prevState, fileSizeErr: false}));

        setApplyPayload((prevState) => ({...prevState, selectedFile: e.target.files}));
    }

    const handlCaptchaChange = (resToken) => {
        resToken && dispatch(captchaVerify(resToken));
        setErrs({...errs, captchErr: false});
    }

    const handleApplySubmit = () => {
        !applyPayload.email ? setErrs((prevState) => ({...prevState, mailErr: true})) 
            : setErrs((prevState) => ({...prevState, mailErr: false}));
        !applyPayload.name ? setErrs((prevState) => ({...prevState, nameErr: true})) 
            : setErrs((prevState) => ({...prevState, nameErr: false}));
        !applyPayload.selectedFile ? setErrs((prevState) => ({...prevState, fileErr: true})) 
            : setErrs((prevState) => ({...prevState, fileErr: false}));
        !isCaptchaVerified ? setErrs((prevState) => ({...prevState, captchErr: true})) :
            setErrs((prevState) => ({...prevState, captchErr: false}));
        if(Object.values(errs).every(item => item === false)) {
            dispatch(submitJobApplication(applyPayload));
            handleClose();
        }else{
            execScroll();
        }
    }

    return (
        <div>
            <Dialog open={modalState || false} onClose={handleClose} aria-labelledby="apply-dialog-title">
                <DialogTitle id="apply-dialog-title">Apply for this Job</DialogTitle>
                <DialogContent>
                    <Grow in={true} timeout={1500}>
                        <Grid container className={classes.formContainer} ref={formRef}>
                            <Grid item xs={12} className={classes.formInputs}>
                                <TextField required id="name" label="Name" variant="outlined" 
                                    className={classes.fields} onChange={(e) => handleTxt(e, 'name')}  error={errs.nameErr} 
                                    helperText={errs.nameErr && `Enter Name`} value={applyPayload.name}/>
                            </Grid>
                            <Grid item xs={12}  className={classes.formInputs}>
                                <TextField className={classes.fields} required id="email" label="Email" 
                                    variant="outlined" type='email' onChange={(e) => handleTxt(e, 'email')}  
                                    error={errs.validationErr || errs.mailErr}  helperText={errs.validationErr ? 
                                    `Enter valid email address`: (errs.mailErr ? `Enter email address` : null)} />
                            </Grid>
                            <Grid item xs={12}  className={classes.formInputs}>
                                <TextField className={classes.fields} id="phone" label="Phone" variant="outlined" 
                                type='number' InputLabelProps={{ shrink: true, }} onChange={(e) => handleTxt(e, 'phone')}
                                value={applyPayload.phone === 0 ? '' : applyPayload.phone} />
                            </Grid>
                            <Grid item xs={12}  className={classes.formInputs}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Button variant="contained" component="label" > Upload Your CV
                                            <input type="file" hidden onChange={(e) => handleFileSelection(e)}/>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.fileInput}>
                                        <Typography variant="subtitle1" >
                                            {errs.fileErr ?  'Please Select a file to upload in MS Word/PDF only (5MB max size)' : 
                                                errs.fileExtErr ? 'Please select only MS word or PDF formats' : 
                                                    errs.fileSizeErr ? 'Please select file with max 5MB size': 
                                            `Selected File: ${applyPayload.selectedFile && applyPayload.selectedFile.length > 0 ? 
                                            applyPayload.selectedFile[0].name : ''}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}  className={classes.formInputs}>
                                <ReCAPTCHA sitekey={site_key} onChange={handlCaptchaChange} />
                                <Typography variant="subtitle1" className={classes.fileInput}>
                                    {errs.captchErr &&  'Verify reCAPTCHA before submitting'}
                                </Typography>
                            </Grid>   
                        </Grid>
                    </Grow>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleApplySubmit} color="primary" 
                    className={ classes.submitBtn }>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ApplyModal;