import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Grid} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDonate } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    container: {
        color: '#1A355D',
        backgroundColor : 'white',
        boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)',
        borderRadius: '60px'
    },
    descTitle: {
        color: '#d04e53',
        padding: '20px 10px'
    },
    payContainer: {
        color: '#22477E',
        borderRadius: '300px',
        padding: '20px',
        borderWidth: 0,
        cursor: 'pointer',
        margin: '5px 0',
        '&:hover': {
            backgroundColor: '#22477E',
            color: 'white',
            animation: 0
        }
    },
    payTxt:{
        display: 'inline-block',
        paddingLeft: '10px'
    },
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0.5 },
    },
    blink: {
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'cubic-bezier(0.1, -0.6, 0.2, 0)',
        animationIterationCount: 'infinite',
    },
  }));

const PayPalDonateView = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container className={classes.container}  alignItems="center">
                <Grid item xs={12} sm={7} className={classes.descTitle}>
                    <Typography variant="h4">
                        Your Contributions Make It Possible!
                    </Typography>
                </Grid>
                <Grid item  xs={12} sm={5}>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank" >
                        <input type="hidden" name="cmd" value="_s-xclick"/>
                        <input type="hidden" name="hosted_button_id" value="F83RSAG9W88KE"/>
                        <button className={clsx(classes.payContainer, classes.blink)} title="click to donate via PayPal">
                            <FontAwesomeIcon icon={faDonate}  size='3x'/> 
                            <Typography variant="h4" style={{ textTransform : 'capitalize', padding: '0 10px'}}>
                                Donate (Paypal)
                            </Typography> 
                    </button> 
                    </form>
                </Grid>
            </Grid>
           
        </div>
    )
}
export default PayPalDonateView;