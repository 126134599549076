export const adoptContent = {
    title: `What Children are Available for Adoption?`,
    contents: [
        {
            key: 'content1',
            value: `You have probably given a lot of though to what children are available for adoption 
            and which type of child you feel may thrive in your home. Once your homestudy is complete, 
            your Case Worker may have some good ideas about what kind of child may be a perfect match. 
            If you are working through an adoption agency, it probably already has children in its care. 
            There are different child placement agencies in your state and surrounding areas.`
        },
        {
            key: 'content2',
            value: `Most children available for adoption directly from foster care are usually older children, 
            with the average age being between 8-10 years old. Some are pre-teens or teenagers, with almost 50% 
            coming from minority cultures. Over 40% of the children are from sibling groups, which sometimes may 
            need to be adopted together. Some children have emotional problems, resulting from neglect, abuse, abandonment, 
            and the lack of stability that comes from being a part of the foster care system. Some may have physical 
            or mental challenges, as well.`
        },
        {
            key: 'content3',
            value: `Many of the children available for adoption are living in foster homes, with trained foster parents. 
            These kids are involved in school, play in their neighborhoods and take part in the community activities of 
            their area. Some children are in group homes, where they live with other foster children. If a child has 
            severe behavior or emotional problems, they could also be temporarily living in a treatment facility, while 
            their condition is being treated.`
        },
        {
            key: 'content4',
            value: `There are over half a million children in the foster care system in the US, usually managed at the 
            state and county level. These children have become a part of the foster care system because they have been 
            removed from their biological parents due to circumstances beyond the child’s control. This can include being 
            removed due to severe neglect or abuse, due to death or severe disability of their biological parents, or even 
            because their biological parents can no longer take care of a child and have given up their legal parental rights.`
        },
        {
            key: 'content5',
            value: `Many states have websites that feature pictures and details of available, adoptable children. There are 
            thousands of children in need in your community and throughout the country. Searching on your own is great, but 
            also enlist the help of your Case Worker, a trusted adoption agency, or even someone you know who has adopted to 
            learn more about how they were able to find their adopted child.`
        },
        {
            key: 'content6',
            value: `All of these children want and need a home. They are loving children who need the love and security that 
            comes from a permanent family and home.`
        }
    ]
}