export const eventsContent = {
    locations: [
        {
            key: `saEvents`,
            title: `San Antonio`,
            value:  `San Antonio Events`
        },
        {
            key: `ausEvents`,
            title: `Austin`,
            value:  `Austin Events`
        }
    ]
}