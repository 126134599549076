import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parentContent1, parentContent2, parentContent3 } from './FosterParentContent';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbMenu from '../../layout/BreadcrumbMenu';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    parentTitle:{
          padding: '0 16px',
          color: '#d04e53'
      },
    parentContainer: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '10px 0',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
        },
    secList: {
        padding: '0 20px'
    },
    listIcon:{
        color: '#22477E',
        minWidth: '25px'
      },
  }));
  
const FosterParentView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Become Foster Parent" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            How to Become a Foster Parent
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.parentContainer}>
                    <Grid item className={classes.parentTitle} xs={12}>
                            <Typography variant="h4" >
                                {parentContent1.title}
                            </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            {parentContent1.caseWorker.map((content) => (
                                <ListItem component="div" key={content.key}>
                                    <ListItemText primary={content.value} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.parentContainer}>
                    <Grid item className={classes.parentTitle} xs={12}>
                        <Grow in={true} timeout={1500}>
                            <Typography variant="h4" >
                                {parentContent2.title}
                            </Typography>
                        </Grow>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            {parentContent2.wellBeing.map((content) => (
                                <ListItem component="div" key={content.key}>
                                    <ListItemText primary={content.value} />
                                </ListItem>
                            ))}
                        </List>
                        <List component="div" aria-label="card-secondary-content" className={classes.secList}>
                            {parentContent2.wellBeingSecList.map((secListItem) => (
                                <ListItem component="div" key={secListItem.key}>
                                <ListItemIcon className={classes.listIcon}>
                                    <FontAwesomeIcon icon={faCaretRight}/>
                                </ListItemIcon>
                                    <ListItemText primary={`${secListItem.label} -  ${secListItem.value}`} /> 
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.parentContainer}>
                    <Grid item className={classes.parentTitle} xs={12}>
                        <Typography variant="h4" >
                            {parentContent3.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            {parentContent3.makeTime.map((item) => (
                                <ListItem component="div" key={item.key}>
                                    <ListItemText primary={item.value} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default FosterParentView;
