import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Zoom, Hidden } from '@material-ui/core';
import {Skeleton } from '@material-ui/lab';
import Carousel from 'react-material-ui-carousel';
import FotoJet1 from "../assets/sliders/FotoJet1.jpg";
import FotoJet2 from "../assets/sliders/FotoJet2.jpg";
import FotoJet3 from "../assets/sliders/FotoJet3.jpg";
import FotoJet4 from "../assets/sliders/FotoJet4.jpg";
import FotoJet5 from "../assets/sliders/FotoJet5.jpg";

const useStyles = makeStyles((theme) => ({
    carousel:{
      margin: '0 0 10px 0'
    },
    imgStlyes: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)'
    },
    imgSubStyles: {
      height: 'auto',
      width:'100%',
    }
  }));

const ImageSlider = () => {
    const classes = useStyles();
    
    let slides = [
        <img  src={FotoJet1} className={classes.imgSubStyles} alt="1" />,
        <img  src={FotoJet2} className={classes.imgSubStyles} alt="2" />  ,
        <img  src={FotoJet3} className={classes.imgSubStyles} alt="3" />  ,
        <img  src={FotoJet4} className={classes.imgSubStyles} alt="4" />  ,
        <img src={FotoJet5}  className={classes.imgSubStyles} alt="5" />   
      ];

    return (
        <Hidden>
          <Grid container justifyContent='center' alignItems="center" className={classes.carousel}>
            <Carousel>
              {
                slides.map( (item, i) => 
                  <Zoom  in={true} timeout={500} key={i}>
                    <Grid md={12} item key={i} className={classes.imgStlyes}>
                      {item || <Skeleton variant="rectangular" width={210} height={450} animation="wave"/>}
                    </Grid> 
                  </Zoom >)
              }
            </Carousel>
          </Grid>
        </Hidden>
    )
}

export default ImageSlider;
