import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText } from '@material-ui/core';
import { donateContent, donateSection } from './DonationContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../paypalDonate/PayPalDonateView';
import ImageSlider from '../../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    donateTitle:{
          padding: '0 16px',
          color: '#d04e53'
      },
    donateTitleTypes:{
        textAlign: 'left',
        padding: '0 16px',
        color: '#1A355D'
    },
    donateContainer: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '30px',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
        },
    address: {
        padding: '0 30px',
        '& .MuiTypography-body1': {
            fontWeight: 'bold',
        }
    },
    donateLink: {
        color: '#22477E',
        cursor: 'pointer',
        padding: '10px 0',
        '& button': {
          color: 'white',
          cursor: 'pointer',
          backgroundColor: '#22477E',
          '&:hover': {
            backgroundColor: '#485f81',
            color: 'white',
            animation: 0
            }
        }
      },
  }));
  
const DonationView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Donation" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Donation
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.donateContainer}>
                    <Grid item className={classes.donateTitle} xs={12}>
                            <Typography variant="h4" >
                                {donateContent.title}
                            </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            {donateContent.mainContent.map((content) => (
                                <ListItem component="div" key={content.key}>
                                    <ListItemText primary={content.value} />
                                    {content.hasLink && 
                                        <form action="https://www.paypal.com/cgi-bin/webscr"  className={classes.donateLink} method="post" target="_blank" >
                                        <input type="hidden" name="cmd" value="_s-xclick"/>
                                        <input type="hidden" name="hosted_button_id" value="F83RSAG9W88KE"/>
                                        <button title="click to donate via PayPal">
                                            Donate
                                        </button> 
                                        </form>}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.donateContainer}>
                {donateContent.gifts.map((item) => (
                    <Fragment key={item.key}>
                    <Grid item className={classes.donateTitleTypes} xs={12}>
                        <Typography variant="h4" >
                            {item.icon} {item.label}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            <ListItem component="div" key={item.key}>
                                <ListItemText primary={item.value} />
                            </ListItem>
                        </List>
                    </Grid>
                    {item.hasLink && 
                        <Grid item xs={12} className={classes.donateLink}>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank" >
                            <input type="hidden" name="cmd" value="_s-xclick"/>
                            <input type="hidden" name="hosted_button_id" value="F83RSAG9W88KE"/>
                            <button title="click to donate via PayPal">
                                Donate
                            </button> 
                            </form>
                        </Grid>}
                    </Fragment>
                ))}
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.donateContainer}>
                    <Grid item className={classes.donateTitleTypes} xs={12}>
                        <Typography variant="h4" >
                            <FontAwesomeIcon icon={donateSection.icon}/> {donateSection.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            <ListItem component="div">
                                <ListItemText primary={donateSection.text1} />
                            </ListItem>
                            <ListItem component="div">
                                <ListItemText primary={donateSection.text2} />
                            </ListItem>
                            <ListItem component="div" className={classes.address}>
                                <ListItemText primary={donateSection.addressSA1} />
                            </ListItem>
                            <ListItem component="div"  className={classes.address}>
                                <ListItemText primary={donateSection.addressSA2} />
                            </ListItem>
                            <ListItem component="div">
                                <ListItemText primary={donateSection.text3} />
                            </ListItem>
                            <ListItem component="div">
                                <ListItemText primary={donateSection.text4} />
                            </ListItem>
                            <ListItem component="div"  className={classes.address}>
                                <ListItemText primary={donateSection.addressAus1} />
                            </ListItem>
                            <ListItem component="div"  className={classes.address}>
                                <ListItemText primary={donateSection.addressAus2} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default DonationView;
