import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, TextField, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { reqInfoSubmitAction } from './RequestInfoActions';
import { getReqPayload } from './RequestInfoSelectors';
import { validateMailFormat } from '../../util/utilities';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    formContainer: {
        color: '#1A355D',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '15px 0',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    sendBtn: {
        color: 'white',
        width: 'calc(100% - 60px)',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            color: 'white',
            backgroundColor: '#485f81',
        }
    }
  }));

const RequestInfoForm = () => {
    const errObjInitialState = {
        nameErr: null, mailErr: null, validationErr: null
    }

    const formRef = useRef(null);
    let mailInput = useRef(null);
    const classes = useStyles();
    const reqPayloadInitState = useSelector(getReqPayload);
    const dispatch = useDispatch();
    const [errs, setErrs ] = useState(errObjInitialState);
    const [reqPayload, setReqPayload] = useState(reqPayloadInitState);

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const clearValues = () => {
        setReqPayload({
            name: '', email: '', phoneNum: 0, subject: '', message: '', sourceInfo: ''
        });
        setErrs({
            nameErr: null, mailErr: null, validationErr: null
        });
        mailInput.current.value = "";
    }

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'name':
                setReqPayload((prevState) => ({...prevState, name: evt.target.value}));
                setErrs({...errs, nameErr: false});
                break;
            case 'email':
                if(validateMailFormat(evt.target.value)){
                    setReqPayload((prevState) => ({...prevState, email: evt.target.value}));
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
                }
                else{
                    setReqPayload((prevState) => ({...prevState, email: ''}));
                    setErrs((prevState) => ({...prevState, validationErr: true, mailErr: false}));
                }
                break;  
            case 'phone':
                setReqPayload((prevState) => ({...prevState, phoneNum: evt.target.value}));
                break;
            case 'subject':
                setReqPayload((prevState) => ({...prevState, subject: evt.target.value}));
                break;
            case 'message':
                setReqPayload((prevState) => ({...prevState, message: evt.target.value}));
                break;
            case `source`:
                setReqPayload((prevState) => ({...prevState, sourceInfo: evt.target.value}));
                break;
            default: 
        }
    }

    const handleSend = () => {
        !reqPayload.name ? setErrs((prevState) => ({...prevState, nameErr: true})) 
        : setErrs((prevState) => ({...prevState, nameErr: false}));
        !reqPayload.email ? setErrs((prevState) => ({...prevState, mailErr: true})) 
        : setErrs((prevState) => ({...prevState, mailErr: false}));

        if(Object.values(errs).every(item => item === false)){
            dispatch(reqInfoSubmitAction(reqPayload))
            clearValues();
        }else {
            execScroll();
        }
    }

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.formContainer}  ref={formRef}>
                    <Grid item xs={12}>
                        <Typography className={classes.desc}> 
                        For more details on becoming a foster parent or to 
                        learn more about what foster care to do in the life of a child, please fill out the form below
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.formInputs}>
                        <TextField required id="name" label="Name" variant="outlined" 
                            className={classes.fields} onChange={(e) => handleTxt(e, 'name')}  error={errs.nameErr} 
                            helperText={errs.nameErr && `Enter Name`} value={reqPayload.name}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields} required id="email" label="Email" 
                        variant="outlined" type='email' onChange={(e) => handleTxt(e, 'email')} 
                        error={errs.validationErr || errs.mailErr}  helperText={errs.validationErr ? 
                            `Enter valid email address`: (errs.mailErr ? `Enter email address` : null)} inputRef={mailInput}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  id="phone" label="Phone" variant="outlined" 
                        type='number' InputLabelProps={{ shrink: true, }} onChange={(e) => handleTxt(e, 'phone')}
                        value={reqPayload.phoneNum === 0 ? '' : reqPayload.phoneNum}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  id="subject" label="Subject" variant="outlined"
                        onChange={(e) => handleTxt(e, 'subject')} value={reqPayload.subject}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  id="message" label="Message" minRows={5} variant="outlined" 
                        multiline onChange={(e) => handleTxt(e, 'message')} value={reqPayload.message}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  id="tellUs" label="Tell us how you heard about FosterCare"
                         minRows={5} variant="outlined" multiline onChange={(e) => handleTxt(e, 'source')} value={reqPayload.sourceInfo}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <Button className={classes.sendBtn} size="medium" color="inherit" 
                            onClick={handleSend}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Grow>
        </div>
    )
}

export default RequestInfoForm;
