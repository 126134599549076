import {REQ_INFO_SUBMIT, REQ_INFO_SUBMIT_SUCCESS, REQ_INFO_SUBMIT_ERROR } from './RequestInfoActions';

const defaultState ={
    reqPayload : {
        name: '',
        email: '',
        phoneNum: 0,
        subject: '',
        message: '',
        sourceInfo: ''
    },
    isRequesting: false
}

const RequestInfoReducer = (state = defaultState, action) => {
    switch(action.type){
        case REQ_INFO_SUBMIT: 
            return {...state, reqPayload: action.payload, isRequesting: true };
        case REQ_INFO_SUBMIT_SUCCESS:
        case REQ_INFO_SUBMIT_ERROR:
            return {state: defaultState, isRequesting: false};
        default : 
            return state;
    }
}
export default RequestInfoReducer;