import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText, ListItemIcon, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { linksContent } from './HelpfulLinksContent';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../paypalDonate/PayPalDonateView';
import ImageSlider from '../../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    linksTitle:{
        padding: '0 16px',
        color: '#d04e53'
      },
    linksContainer: {
        background: 'linear-gradient(153deg, #95add1b0 30%, white 90%)',
        color: '#1A355D',
        padding: '10px 0',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
    },
    listLinks: {
        padding: '0 30px',
        alignItems: 'center'
    },
    link: {
        minWidth: '20px',
        color: '#1A355D'
    }
  }));
  
const HelpfulLinksView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Helpful Links" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Helpful Links
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography > {linksContent.desc} </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.linksContainer}>
                    {linksContent.links.map((item) => (
                        <Grid item xs={12} key={item.key}>
                            <List component="div" aria-label="helpfulLinks content">
                                <ListItem component="div"  className={classes.listLinks}>
                                    <ListItemIcon className={classes.link}>
                                        <FontAwesomeIcon icon={faLink} />
                                    </ListItemIcon>
                                    <Link target='_blank' href={item.value}>
                                        <ListItemText primary={item.label} />
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                    ))}
                   
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default HelpfulLinksView;
