export const volunteerOpp = [
{
    key: `opportunities`,
    title: `Volunteer Opportunities with Foster Kids`,
    contents: [
        {
            key: 'content1',
            value: `The most important way to volunteer with foster kids is to become a foster parent. We continuously 
            search for people in the community to provide loving, nurturing and healing homes to abused and neglected 
            children who have been identified by the Texas Department of Family and Protective Services.`
        },
        {
            key: 'content2',
            value: `The need is great. There are over 10,000 Texas children waiting for foster and/or adoptive homes. 
            In Bexar County, there are as many as 250 children a month who need to be placed in a foster home. When foster 
            homes are not found, these children are placed in emergency shelters and continue to wait for a foster family.`
        },
        {
            key: 'content3',
            value: `To learn more about becoming a Foster parent, see our `
        }
    ]
},
{
    key: `mentoring`,
    title: `Become a Mentor`,
    contents: [
        {
            key: 'content11',
            value: `Foster Care children and youth travel life alone. They seldom have a special 
            person to assure them that their dreams can come true.`
        },
        {
            key: 'content12',
            value: `There is often no one to hear their problems and pat them on the back for small successes.`
        },
        {
            key: 'content13',
            label: `What can you do?`,
            value: [
                {
                    key: `do1`,
                    value: `Mentors share life experiences`
                },
                {
                    key: `do2`,
                    value: `Show youth that positive choices result in a positive life`
                },
                {
                    key: `do3`,
                    value: `Mentors take youth to the movies or share a meal`
                },
                {
                    key: `do4`,
                    value: `Explore careers and higher education`
                },
                {
                    key: `do5`,
                    value: `Set goals for the future, and have fun!`
                }
            ]
        }
    ]
},
]
