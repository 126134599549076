import {APPLY_MODAL_STATE, CAPTCHA_VERIFY, CAPTCHA_VERIFY_SUCCESS, CAPTCHA_VERIFY_ERROR,
    SUBMIT_JOB_APPLICATION, SUBMIT_JOB_APPLICATION_SUCCESS, SUBMIT_JOB_APPLICATION_ERROR} from './CareersActions';

const defaultState = {
    applyModalState: false,
    isVerifying: false,
    isCaptchaVerified: false,
    captchaPayload: [],
    applyPayload: {
        name: '', email: '', phone: 0, selectedFile: undefined
    },
    isSubmitting: false,
    submitResponse: {}
}

const CareersReducer = (state = defaultState, action) => {
    switch(action.type){
        case APPLY_MODAL_STATE: 
            return {...state, applyModalState: action.payload, isCaptchaVerified: false, captchaPayload: [] };
        case CAPTCHA_VERIFY: 
            return {...state, isVerifying: true};
        case CAPTCHA_VERIFY_SUCCESS:
            return {...state, isCaptchaVerified: true, isVerifying: false, captchaPayload: action.payload};
        case CAPTCHA_VERIFY_ERROR:
            return {...state, isCaptchaVerified: false, isVerifying: false, captchaPayload: []};
        case SUBMIT_JOB_APPLICATION:
            return {...state, applyPayload: action.payload, isSubmitting: true};
        case SUBMIT_JOB_APPLICATION_SUCCESS:
        case SUBMIT_JOB_APPLICATION_ERROR:
            return {state: defaultState, submitResponse: action.payload, isSubmitting: false};
        default : 
            return state;
    }
}
export default CareersReducer;