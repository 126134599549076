import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Tabs, Tab,  Box,  Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import { eventsContent } from './EventsContent';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`events-tabpanel-${index}`} 
            aria-labelledby={`events-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}> {children} </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 4px 11px 1px rgb(90 78 80 / 55%)',
        backgroundColor: 'white',
    },
    desc: {
        fontWeight: '800',
        padding: `20px 0`
    },
    subTxt: {
        textAlign: 'left'
    },
    card: {
        width: '100%',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardsItems:{
        padding: '20px'
      },
    media:{
        padding: '15px',
        color: '#22477E'
      },
  }));

const a11yProps = (index) => {
    return {
        id: `events-tab-${index}`,
        'aria-controls': `events-tabpanel-${index}`,
    };
}

const EventsView = () => {
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Events
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="events tabs"
                className={classes.tabs}
            >
                 {eventsContent.locations.map((tab, index) => (
                 <Tab label={tab.title} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <Card className={classes.card}> 
                            <CardActionArea> 
                                <CardMedia className={classes.media} title={`San Antonio Events`} >
                                    <iframe src={`https://calendar.google.com/calendar/embed?src=32sk14p0onvfbsmpsptnumtgnc%40group.calendar.google.com&ctz=America/Chicago`}
                                    style={{border: `solid 1px #777`, width: `100%`}} height="600" scrolling="no" title={`sa Events`}></iframe>
                                </CardMedia>
                                <CardContent>
                                    <EventNoteIcon />
                                    <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                                        San Antonio Events
                                    </Typography>
                                </CardContent>           
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grow>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                    <Card className={classes.card}> 
                            <CardActionArea> 
                                <CardMedia className={classes.media} title={`Austin Events`} >
                                    <iframe src={`https://calendar.google.com/calendar/embed?src=hke38gn6coph4tsckco3lf1i5k%40group.calendar.google.com&ctz=America/Chicago`}
                                    style={{border: `solid 1px #777`, width: `100%`}} height="600" scrolling="no" title={`austin Events`}></iframe>
                                </CardMedia>
                                <CardContent>
                                    <EventNoteIcon />
                                    <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                                        Austin Events
                                    </Typography>
                                </CardContent>           
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grow>
            </TabPanel>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default EventsView;