import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Card, CardActionArea, CardContent, CardMedia, 
    List, ListItem, ListItemText, ListItemIcon, Button, CardActions} from '@material-ui/core';
import { careers } from './CareersContent';
import WorkIcon from '@material-ui/icons/Work';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import ImageSlider from '../../layout/ImageSlider';
import { handleApplyModal } from './CareersActions';
import ApplyModal from './ApplyModal';
import { getIsSubmitting } from './CareersSelectors';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import Spinner from '../spinner/Spinner';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px 16px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
        boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)'
    },
    subTxt: {
        textAlign: 'left'
    },
    card: {
        width: '100%',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    descTitle: {
        color: '#d04e53',
        paddingBottom: '10px',
        textAlign: 'left'
    },
    gridTitle:{
        color: '#d04e53',
        textAlign: 'center'
    },
    cardsItems:{
        padding: '20px'
      },
    gridItems:{
        padding: '0 20px'
      },
    media:{
        paddingTop: '15px',
        color: '#22477E'
      },
    applyNow: {
    justifyContent:'center',
    padding: '20px',
    '& .MuiButtonBase-root': {
        color: 'white',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: '#22477E'
    }
    },
    disableScr: {
        pointerEvents: 'none',
    },
    listIcon:{
        color: '#22477E',
        minWidth: '25px'
      },
  }));

const CareersView = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isSubmitting = useSelector(getIsSubmitting);

    const handleApply = () => {
        dispatch(handleApplyModal(true));
    }

    return (
        <div className={isSubmitting ? classes.disableScr : classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Careers
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="h6" className={classes.subTxt}>
                        Texas Foster Care and Adoption Services is a non-profit foster care and adoption 
                        agency serving many surrounding counties. We are looking for a talented detail driven 
                        individual who has a desire to work with children.
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.panelContainer}>
                    {careers.map((job) => (
                        <Grid item xs={12} key={job.key}> 
                            <Card className={classes.card}> 
                                <CardActionArea> 
                                    <CardMedia className={classes.media} title={job.title} >
                                       <WorkIcon />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                                            {job.title}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                            {job.descTitle}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                            {job.desc}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                            {job.skillDesc}
                                        </Typography>
                                        <List component="div" aria-label="card-content">
                                            {job.skillSet.map((listItem) => (
                                            <ListItem component="div" className={classes.listLinks} key={listItem.key}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <FontAwesomeIcon icon={faHandPointRight}  size='2x'/>
                                                </ListItemIcon>
                                                <ListItemText primary={listItem.value} />
                                            </ListItem>
                                            ))}
                                        </List>
                                        <Grid container>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.salaryTitle} : 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.salary}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.locationTitle} : 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.location}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.expTitle}: 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.exp}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.travelTitle} : 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.travelReq}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.educationTitle} : 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.educationReq}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.gridItems}>
                                                <Typography gutterBottom variant="h6" className={classes.descTitle}>
                                                    {job.jobStatusTitle} : 
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.subTxt}>
                                                    {job.jobStatus}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>           
                                </CardActionArea>
                                <CardActions className={classes.applyNow}>
                                    <Button size="small" color="inherit" onClick={handleApply} >
                                        Apply Now
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grow>
            <ApplyModal />
            {isSubmitting && <Spinner />}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default CareersView;