import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Accordion, AccordionDetails, AccordionSummary,
     ListItem, ListItemText, ListItemIcon  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import { aboutContent } from './AboutContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    typeRoot: {
        padding: '10px 0',
        textAlign: 'left'
    },
    title:{
        padding: '0 16px',
        color: '#d04e53',
        textAlign: 'left'
    },
    listLinks: {
        padding: '10px 30px',
        alignItems: 'center'
    },
    caret: {
        minWidth: '20px',
        color: '#1A355D'
    },
    accordionBody: {
        backgroundColor: '#738db33d',
        padding: '40px'
    }
  }));

const AboutView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
        <ImageSlider />
            {aboutContent.map((item, index) => (
                <Accordion key={item.key} expanded={true}>
                    <Grow in={true} timeout={1500}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={index + item.key} id={index + item.key} >
                            <Typography variant="h4" className={classes.title}>{item?.title}</Typography>
                        </AccordionSummary>
                    </Grow>
                    <AccordionDetails className={classes.accordionBody}>
                        <Grid container direction='column' >
                            {item.desc?.map((item) => (
                                <Grid item key={item?.key}>
                                    <Typography variant="body1" className={classes.typeRoot} >
                                        {item?.value}
                                    </Typography>
                                </Grid>
                            ))} 
                            {item?.listHead && 
                                <Grid item>
                                    <Typography variant="h5" className={classes.title} >
                                        {item?.listHead}
                                    </Typography>
                                </Grid>}
                            {item?.list && item.list.map((listItem) => (
                                <Grid item key={listItem?.key}>
                                    <ListItem component="div" key={listItem?.key} className={classes.listLinks}>
                                        <ListItemIcon className={classes.caret}>
                                            <FontAwesomeIcon icon={faCaretRight} size='2x'/>
                                        </ListItemIcon>
                                        <ListItemText primary={listItem?.value} />
                                    </ListItem>
                                </Grid>
                            )) }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default AboutView;