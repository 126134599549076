import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, AppBar, Grid, Toolbar, Button, IconButton, Tooltip, useMediaQuery,
   Menu, MenuItem, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import EventIcon from '@material-ui/icons/Event';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import WorkIcon from '@material-ui/icons/Work';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Texas_Foster_Care256x256 from "../assets/img/logos/Texas_Foster_Care256x256.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDonate } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    grid:{
      padding: '25px 0'
    },
    iconGrid:{
      justifyContent: 'space-evenly'
    },
    head:{
      background: 'linear-gradient(132deg, #22477E 30%, #3567B2 90%)',
      boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)'
    },
    title: {
      flexGrow: 1,
      textAlign: 'left'
    },
    menuLinks:{
      textDecoration: 'none',
      color: 'white',
    },
    menuBtn:{
      backgroundColor: 'unset',
      padding: '6px',
      fontSize: '0.775rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#1A355D',
      }
    },
    hamMenu:{
      '& .MuiMenu-paper':{
        color: 'white',
        // backgroundColor: '#22477E',
        background: 'linear-gradient(132deg, #22477E 30%, #3567B2 90%)',
        width: '200px',
      }
    },
    hamMenuItem: {
      height: '70px',
      '&:hover': {
        backgroundColor: '#1A355D',
      }
    },
    hamMenuIcon: {
      paddingRight: '10px',
    },
    show: {
      display: 'inline-block'
    },
    hide: {
      display: 'none'
    },
    donate: {
      borderWidth: 0,
      padding: '5px 10px',
      borderRadius: '100px',
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: '#1A355D',
          color: 'white',
          animation: 0
      }
  },
  }));
  
  const HeaderMenu = () => {
    const classes = useStyles();
   
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const btnProp = {
      className: isSmallScreen ? classes.hide : clsx(classes.menuBtn)
    };
    const iconProp = {
      className: isSmallScreen ? clsx(classes.menuBtn) : classes.hide
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    

    return (
      <div className={classes.root}>
        <AppBar position="static"  className={classes.head}>
          <Toolbar>
            <Grid container>
              <Grid item xs={10} lg={5} xl={6}>
                <Grid container alignItems="center">
                  <Grid item xs={4} sm={4} md={2} xl={1}>
                    <Link to='/'>
                      <img src={Texas_Foster_Care256x256} alt="fostering, Nurturing, adoption, Foster care, foster to adopt, nurturing" height="80px" width="80px"/>
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={8} md={10} xl={11}>
                    <Link to='/' className={classes.menuLinks}>
                      <Typography variant="h4" className={classes.title}>
                          Texas Foster Care and Adoption Services
                      </Typography>
                      <Typography variant="subtitle1" className={classes.title}>
                          A Texas 501(c)(3) Non-Profit Agency
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} lg={7} xl={6}>
                <Grid container alignItems="center" className={clsx(classes.grid,classes.iconGrid)} >
                  <Grid item   title="click to donate via PayPal">
                    <form action="https://www.paypal.com/cgi-bin/webscr"  method="post" target="_blank" >
                      <input type="hidden" name="cmd" value="_s-xclick"/>
                      <input type="hidden" name="hosted_button_id" value="F83RSAG9W88KE"/>
                      <button className={classes.donate}>
                      <FontAwesomeIcon icon={faDonate}  size='2x'/></button>
                    </form>
                  </Grid>
                  <Tooltip title="Home" aria-label="home" arrow>
                    <Grid item>
                      <Link to="/" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<HomeIcon />} color='inherit' {...btnProp}>Home</Button> 
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="About" aria-label="about" arrow>
                    <Grid item>
                      <Link to="/about" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<InfoIcon />} {...btnProp} color='inherit'>About Us</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Services" aria-label="services" arrow>
                    <Grid item>
                      <Link to="/services" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<ChromeReaderModeIcon />} {...btnProp} color='inherit'>Services</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Contact Us" aria-label="contactUS" arrow>  
                    <Grid item >
                      <Link to="/contactUs" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<ContactPhoneIcon />} {...btnProp} color='inherit'>Contact Us</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Gallery" aria-label="gallery" arrow>  
                    <Grid item>
                      <Link to="/gallery" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<PhotoLibraryIcon />} {...btnProp} color='inherit'>Gallery</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Events" aria-label="events" arrow>  
                    <Grid item>
                      <Link to="/events" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<EventIcon />} {...btnProp} color='inherit'>Events</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Apply" aria-label="apply" arrow>  
                    <Grid item>
                      <Link to="/apply" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<ChildCareIcon />} {...btnProp} color='inherit'>Apply Now</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Careers" aria-label="careers" arrow>  
                    <Grid item>
                      <Link to="/careers" className={classes.menuLinks}>
                        <Button variant="contained" startIcon={<WorkIcon />} {...btnProp} color='inherit'>Careers</Button>
                      </Link>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Menu" aria-label="menu" arrow>
                    <IconButton color="inherit" onClick={handleMenuClick} size="small" {...iconProp}>
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  className={classes.hamMenu}
                >
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/" className={classes.menuLinks}>
                      <HomeIcon fontSize="small" className={classes.hamMenuIcon} /> 
                      Home 
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/about" className={classes.menuLinks}>
                      <InfoIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      About Us 
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/services" className={classes.menuLinks}>
                      <ChromeReaderModeIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      Services 
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/contactUs" className={classes.menuLinks}>
                      <ContactPhoneIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      Contact Us
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/gallery" className={classes.menuLinks}>
                      <PhotoLibraryIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      Gallery
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <EventIcon fontSize="small" className={classes.hamMenuIcon}/> 
                    <Link to="/events" className={classes.menuLinks}>Events</Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/apply" className={classes.menuLinks}>
                      <ChildCareIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      Apply Now
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem className={classes.hamMenuItem}>
                    <Link to="/careers" className={classes.menuLinks}>
                      <WorkIcon fontSize="small" className={classes.hamMenuIcon}/> 
                      Careers
                    </Link>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
    );
  }

  export default HeaderMenu;
