import { faMapMarkedAlt, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';

export const contactUsContent = [
    {
        key: `saContact`,
        title: `San Antonio`,
        contact: [
            {
                key: `saAddress`,
                title: `Address`,
                icon: faMapMarkedAlt,
                line1: `5825 Callaghan Rd, #206`,
                line2: `San Antonio, TX 78228`
            },
            {
                key: `saPhone`,
                title: `Phone`,
                icon: faPhoneSquareAlt,
                line1: `Office:  210-332-9171`,
                line2: `Fax:  210-332-9206`
            }
        ]
    },
    {
        key: `austinContact`,
        title: `Austin`,  
        contact: [
            {
                key: `austinAddress`,
                title: `Address`,
                icon: faMapMarkedAlt,
                line1: `12593 Research Blvd, #302`,
                line2: `Austin, TX 7875`
            },
            {
                key: `austinPhone`,
                title: `Phone`,
                icon: faPhoneSquareAlt,
                line1: `Office:  512-697-9806`,
                line2: `Fax:  210-332-9206`
            }
        ]
    }
]

export const fosterInterests = [
    {
        value: `Traditional Foster Care`
    },
    {
        value: `Foster to Adopt`
    },
    {
        value: `Volunteer`
    },
    {
        value: `Respite Provider`
    },
    {
        value: `Therapeutic Foster Care`
    },
    {
        value: `Charitable Contribution`
    },
    {
        value: `Other`
    }
]

export const fosterSources = [
    {
        value: `Social Media`
    },
    {
        value: `From a friend`
    },
    {
        value: `News Paper`
    }
]