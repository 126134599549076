import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText, Link } from '@material-ui/core';
import { helpKidsContent } from './HelpFosterKidsContent';
import BreadcrumbMenu from '../../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    subTitle:{
        padding: '0 16px',
        color: '#d04e53',
        textAlign: 'left'
      },
    container: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '20px',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
    },
    extLink: {
        textAlign: 'left',
        padding: '0 20px 20px 20px',
    }
  }));
  
const HelpFosterKids = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Help Foster Kids" secParentComp= "Home" parentComp="Articles And Information"
                        secParentRoute="/" parentCompRoute="/articlesInfo"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Ways to Help Children in Foster Care
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"  className={classes.body}>
                            {helpKidsContent.desc}
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.container}>
                    {helpKidsContent.contents.map((content) => (
                        <Fragment key={content.key}>
                            {content.label && <Grid item className={classes.subTitle} xs={12}>
                                <Typography variant="h4" >
                                    {content.label}
                                </Typography>
                            </Grid>}
                            <Grid item xs={12}>
                                <List component="div" aria-label="svc content">
                                    <ListItem component="div" key={content.key}>
                                        <ListItemText primary={content.value} />
                                    </ListItem>
                                </List>
                                <Typography variant="h6"  className={classes.extLink}>
                                    <Link href="https://www.bigmentor.org/" target="_blank">
                                        {content?.extLink}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default HelpFosterKids;
