import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Grow, TextField, Button, Box, FormControl, Select, FormHelperText, InputLabel, 
    Typography, Divider, Checkbox, FormGroup, FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { states, infoOptions } from './ApplyContent';
import { sendApplication } from './ApplyFormActions';
import { getFormPayload } from './ApplySelectors';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    secTitle: {
        color: '#d04e53',
        padding: '10px 0'
    },
    sectionBox: {
        borderRadius: '20px',
        boxShadow: '0px 4px 4px 2px rgb(90 78 80 / 55%)',
        margin: '20px 10px',
        backgroundColor: 'white'
    },
    formContainer: {
        color: '#1A355D',
        paddingBottom: '10px'
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '15px 0',
        color: '#1A355D',
    },
    checkboxGrp:{
        padding: '0 50px',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    radioLegend: {
        color: '#d04e53',
        padding: '0 10px',
        fontSize: '18px'
    },
    sendBtn: {
        color: 'white',
        width: 'calc(100% - 60px)',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            backgroundColor: '#485f81',
            color: 'white',
        }
    }
  }));

const ApplyForm = () => {
    const errObjInitialState = {
        fname1Err: null, lname1Err: null, streetErr: null, cityErr: null, zipErr: null,
        countyErr: null
    }

    const formRef = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const formPayloadInitState = useSelector(getFormPayload);
    const [errs, setErrs ] = useState(errObjInitialState);
    const [formPayload, setFormPayload] = useState(formPayloadInitState);
    const [isAdoptChecked, setIsAdoptChecked] = useState(false);
    const [isCareChecked, setIsCareChecked] = useState(false);

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'fname1':
                setFormPayload({...formPayload, fname1: evt.target.value});
                setErrs({...errs, fname1Err: false});
                break;
            case 'lname1':
                setFormPayload({...formPayload, lname1: evt.target.value});
                setErrs({...errs, lname1Err: false});
                break;
            case 'mname1':
                setFormPayload({...formPayload, mname1: evt.target.value});
                break;
            case 'fname2':
                setFormPayload({...formPayload, fname2: evt.target.value});
                break;
            case 'lname2':
                setFormPayload({...formPayload, lname2: evt.target.value});
                break;
            case 'mname2':
                setFormPayload({...formPayload, mname2: evt.target.value});
                break;
            case 'homePhone':
                setFormPayload({...formPayload, homePhone: evt.target.value});
                break;
            case 'cellPhone':
                setFormPayload({...formPayload, cellPhone: evt.target.value});
                break;
            case 'street':
                setFormPayload({...formPayload, street: evt.target.value});
                setErrs({...errs, streetErr: false});
                break;
            case 'city':
                setFormPayload({...formPayload, city: evt.target.value});
                setErrs({...errs, cityErr: false});
                break;
            case 'state':
                setFormPayload({...formPayload, state: evt.target.value});
                break;
            case 'county':
                setFormPayload({...formPayload, county: evt.target.value});
                setErrs({...errs, countyErr: false});
                break;
            case 'zip':
                setFormPayload({...formPayload, zip: evt.target.value});
                setErrs({...errs, zipErr: false});
                break;
            case 'aboutUs':
                setFormPayload({...formPayload, aboutUs: evt.target.value});
                break;
            case 'ageCheck':
                setFormPayload({...formPayload, ageCheck: evt.target.value});
                break;
            case 'hasSteadyIncome':
                setFormPayload({...formPayload, hasSteadyIncome: evt.target.value});
                break;
            case 'comfortCheckyn':
                setFormPayload({...formPayload, comfortCheck: evt.target.value});
                break;
            case 'assistMessage':
                setFormPayload({...formPayload, assistMessage: evt.target.value});
                break;
            case 'relationshipMessage':
                setFormPayload({...formPayload, relationshipMessage: evt.target.value});
                break;
            case 'experienceMessage':
                setFormPayload({...formPayload, experienceMessage: evt.target.value});
                break;
            default: 
        }
    }

    const clearValues = () => {
        setFormPayload({
            fname1: '', lname1: '', mname1: '', fname2: '', lname2: '', mname2: '', homePhone: 0, cellPhone: 0, street: '', 
        city: '', state: 'Texas', zip: 0, county: '', adopt: '', care: '', aboutUs: '', ageCheck: 'no', hasSteadyIncome : 'no', 
        comfortCheck: 'no', assistMessage: '', relationshipMessage: '', experienceMessage: ''
        });
        setErrs({
            fname1Err: null, lname1Err: null, streetErr: null, cityErr: null, zipErr: null,
        countyErr: null
        })
        setIsAdoptChecked(false);
        setIsCareChecked(false);
    }

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleInterestChange = (e, txt) => {
        if(e.target.checked){
            if(txt === "Adoption") {
                setFormPayload({...formPayload, adopt: txt});
                setIsAdoptChecked(true);
            } else{
                setFormPayload({...formPayload, care: txt});
                setIsCareChecked(true);
            }
        }
    }
    const validateForm = (payloadObj) => {

        !payloadObj.fname1 ? setErrs((prevState) => ({...prevState, fname1Err: true})) 
        : setErrs((prevState) => ({...prevState, fname1Err: false}));
        !payloadObj.lname1 ? setErrs((prevState) => ({...prevState, lname1Err: true})) 
        : setErrs((prevState) => ({...prevState, lname1Err: false}));
        !payloadObj.street ? setErrs((prevState) => ({...prevState, streetErr: true})) 
        : setErrs((prevState) => ({...prevState, streetErr: false}));
        !payloadObj.city ? setErrs((prevState) => ({...prevState, cityErr: true})) 
        : setErrs((prevState) => ({...prevState, cityErr: false}));
        !payloadObj.state ? setErrs((prevState) => ({...prevState, stateErr: true})) 
        : setErrs((prevState) => ({...prevState, stateErr: false}));
        !payloadObj.zip && payloadObj.zip === 0 ? setErrs((prevState) => ({...prevState, zipErr: true})) 
        : setErrs((prevState) => ({...prevState, zipErr: false}));
        !payloadObj.county ? setErrs((prevState) => ({...prevState, countyErr: true})) 
        : setErrs((prevState) => ({...prevState, countyErr: false}));
    }

    
    const handleApplySend = () => {
        validateForm(formPayload);
        if(Object.values(errs).every(item => item === false)){
            dispatch(sendApplication(formPayload));
            clearValues();
        }else {
            execScroll();
        }
    }

    return (
        <div className={classes.root}>
            <Grid container ref={formRef}>
                <Grid item xs={12} md={6}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <Typography variant="h4" className={classes.secTitle}>
                                Adult 1
                                <Divider/ >
                            </Typography>
                            <Grid container className={classes.formContainer}>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="fname1" label="First Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'fname1')} error={errs.fname1Err} 
                                        helperText={errs.fname1Err && `Enter First Name`} value={formPayload.fname1}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="lname1" label="Last Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'lname1')} error={errs.lname1Err} 
                                        helperText={errs.lname1Err && `Enter Last Name`} value={formPayload.lname1}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="mname1" label="Middle Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'mname1')}  value={formPayload.mname1}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField className={classes.fields}  id="homePhone" label="Home Phone" variant="outlined" 
                                    type='number' InputLabelProps={{ shrink: true, }} onChange={(e) => handleTxt(e, 'homePhone')}
                                    value={formPayload.homePhone === 0 ? '' : formPayload.homePhone}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField className={classes.fields}  id="cellPhone" label="Cell Phone" variant="outlined" 
                                    type='number' InputLabelProps={{ shrink: true, }} onChange={(e) => handleTxt(e, 'cellPhone')}
                                    value={formPayload.cellPhone === 0 ? '' : formPayload.cellPhone}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <Typography variant="h4" className={classes.secTitle}>
                                Contact Info
                                <Divider/ >
                            </Typography>
                            <Grid container className={classes.formContainer}>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="street" label="Street" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'street')} error={errs.streetErr} 
                                        helperText={errs.streetErr && `Enter Street Information`} value={formPayload.street}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="city" label="City" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'city')} error={errs.cityErr} 
                                        helperText={errs.cityErr && `Enter City`} value={formPayload.city}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <FormControl variant="outlined" className={classes.fields} required error={errs.stateErr}>
                                        <InputLabel htmlFor="native-select-state">State</InputLabel>
                                        <Select native value={formPayload.state} 
                                            onChange={(e) => handleTxt(e, 'state')} label="State"
                                            inputProps={{
                                                name: 'State',
                                                id: 'native-select-state',
                                            }}
                                        >
                                            {states.map((item) => (
                                                <option value={item} key={item}>{item}</option>
                                            ))}
                                        </Select>
                                        <FormHelperText  >{errs.stateErr && `Select a state `}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField required className={classes.fields}  id="zip" label="Zip" variant="outlined" 
                                        type='number' InputLabelProps={{ shrink: true, }} 
                                        onChange={(e) => handleTxt(e, 'zip')} error={errs.zipErr}   helperText={errs.zipErr && `Enter Zip Code`}
                                        value={formPayload.zip}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField required id="county" label="County" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'county')} error={errs.countyErr} 
                                        helperText={errs.countyErr && `Enter County`} value={formPayload.county}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <Typography variant="h4" className={classes.secTitle}>
                                Adult 2
                                <Divider/ >
                            </Typography>
                            <Grid container className={classes.formContainer}>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="fname2" label="First Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'fname2')} value={formPayload.fname2}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="mname2" label="Middle Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'mname2')} value={formPayload.mname2}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="lname2" label="Last Name" variant="outlined" 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'lname2')} value={formPayload.lname2}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={12} md={6}>
                        <Grid container alignItems='center'>
                            <Grid item xs={12}>
                                <Grow in={true} timeout={1500}>
                                    <Box className={classes.sectionBox}>
                                        <Typography variant="h4" className={classes.secTitle}>
                                            Areas of Interest
                                            <Divider/ >
                                        </Typography>
                                        <Grid container className={classes.formContainer} justifyContent="center">
                                            <Grid item xs={12} className={classes.checkboxGrp}>
                                                <FormGroup style={{flexDirection: 'row'}}>
                                                    <FormControlLabel control={<Checkbox checked={isAdoptChecked} 
                                                        onChange={(e) => handleInterestChange(e, 'Adoption')}/> } 
                                                        label="Adoption" />
                                                    <FormControlLabel control={<Checkbox checked={isCareChecked} 
                                                        onChange={(e) => handleInterestChange(e, 'Foster Care')}/>} 
                                                        label="Foster Care" />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>
                            </Grid>
                            <Grid item xs={12}>
                                <Grow in={true} timeout={1500}>
                                    <Box className={clsx(classes.sectionBox, classes.formContainer)}>
                                        <Typography variant="h4" className={classes.secTitle}>
                                            How did you hear about us?
                                            <Divider/ >
                                        </Typography>
                                        <FormControl variant="outlined" className={classes.fields}>
                                            <Select native value={formPayload.aboutUs} 
                                                onChange={(e) => handleTxt(e, 'aboutUs')}
                                                inputProps={{
                                                    name: 'aboutUs',
                                                    id: 'native-select-aboutUs',
                                                }}
                                            >
                                                {infoOptions.map((item) => (
                                                    <option value={item} key={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grow>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} md={4}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <FormControl component="outlined" className={clsx(classes.fields, classes.formInputs)}>
                                <FormLabel component="legend" className={classes.radioLegend} >Are you over 21?</FormLabel>
                                <RadioGroup row style={{justifyContent:'center'}}
                                    aria-label="ageCheck"
                                    name="ageCheck"
                                    value={formPayload.ageCheck}
                                    onChange={(e) => handleTxt(e, 'ageCheck')}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grow> 
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <FormControl component="outlined" className={clsx(classes.fields, classes.formInputs)}>
                                <FormLabel component="legend" className={classes.radioLegend} >Do you have steady income?</FormLabel>
                                <RadioGroup row style={{justifyContent:'center'}}
                                    aria-label="hasSteadyIncome"
                                    name="hasSteadyIncome"
                                    value={formPayload.hasSteadyIncome}
                                    onChange={(e) => handleTxt(e, 'hasSteadyIncome')}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grow> 
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <FormControl component="outlined" className={clsx(classes.fields, classes.formInputs)}>
                                <FormLabel component="legend" className={classes.radioLegend}>
                                    Do you have enough sleeping and living space for more children?
                                </FormLabel>
                                <RadioGroup row style={{justifyContent:'center'}}
                                    aria-label="comfortCheckyn"
                                    name="comfortCheckyn"
                                    value={formPayload.comfortCheck}
                                    onChange={(e) => handleTxt(e, 'comfortCheckyn')}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grow> 
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grow in={true} timeout={1500}>
                        <Box className={classes.sectionBox}>
                            <Typography variant="h4" className={classes.secTitle}>
                                Other Information
                                <Divider/ >
                            </Typography>
                            <Grid container className={classes.formContainer}>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="assistMessage" label="Please tell us a little about how we can assist you." 
                                        variant="outlined" multiline minRows={4} maxRows={10}
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'assistMessage')}
                                        value={formPayload.assistMessage} />
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="relationshipMessage" label="Please tell us how long you have been married or in a committed relationship." 
                                        variant="outlined" multiline minRows={4} maxRows={10} 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'relationshipMessage')}
                                        value={formPayload.relationshipMessage} />
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField id="experienceMessage" label="Please tell us about your past experience as a foster parent if any." 
                                        variant="outlined" multiline minRows={4} maxRows={10} 
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'experienceMessage')} 
                                        value={formPayload.experienceMessage} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grow in={true} timeout={1500}>
                        <Button onClick={handleApplySend} color="primary" 
                            className={ classes.sendBtn }>
                            Send
                        </Button>
                    </Grow>
                </Grid>
            </Grid>
        </div>
    )
}

export default ApplyForm;
