export const joinUsContent = [
    {
        key: `careerTab`,
        title: `Career`,
        desc1: `Texas Foster Care and Adoption Services employs experienced and qualified:`,
        desc2: `For information about current openings and opportunities, please contact us at:`,
        listItems: [
            {
                key: `careers1`,
                value: `Administrators`
            },
            {
                key: `careers2`,
                value: `Case Managers`
            },
            {
                key: `careers3`,
                value: `Clinicians`
            },
            {
                key: `careers4`,
                value: `Family Advocates`
            },
            {
                key: `careers5`,
                value: `Foster Caregivers`
            },
            {
                key: `careers6`,
                value: `Support staff`
            },
            {
                key: `careers7`,
                value: `Trainers`
            }
        ],
        address: [
            {
                key: `address1`,
                value: `5825 Callaghan Road,Suite 206,`
            },
            {
                key: `address2`,
                value: `San Antonio, TX 78228`
            },
            {
                key: `address3`,
                value: `Tel: 210-332-9171`
            },
            {
                key: `address4`,
                value: `Fax: 210-332-9206`
            }
        ]
    },
   {
        key: `foster tab`,
        title: `Foster Caregivers`,
        desc1: `We Need Your Help to Change Children's Lives.`,
        desc2: `Foster Caregiver essential duties and responsibilities include the following:`,
        subText1: `Join Us!`,
        subText2: `Great Training Makes Great Foster Parents`,
        subText3: `We invite you to call our experienced Foster Home Developer at 210-332-9171 with any questions or to register for the next training opportunity.`,
        listItems: [
            {
                key: `careGiver1`,
                value: `Free monthly foster parent training`
            },
            {
                key: `careGiver2`,
                value: `Free PRIDE 24-hour pre-training course`
            },
            {
                key: `careGiver3`,
                value: `Documentation of children’s activities`
            },
            {
                key: `careGiver4`,
                value: `On-going guidance and training to meet the specific needs of your foster home`
            }
        ]
    },
    {
        key: `requestInfoTab`,
        title: `Request Information`,
    }
]