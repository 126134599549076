export const parentContent1 = {
    title: `Expect to work with the child's caseworker`,
    caseWorker: [
        {
            key: 'caseWorker1',
            value: `Since most children in foster care will have regular visits with their birth parents,
             or the family members from which they have been removed (including siblings), a caseworker will 
             be involved in organizing these visits. Though the court will make the decisions on supervision, 
             the caseworker will be coordinating the time and location of the visits.`
        },
        {
            key: 'caseWorker2',
            value: `With the exception of cases where extreme abuse has occurred, most often the plan of the caseworker
             is to return the child to their original home. The biological parents are provided help to aid in this process, 
             and the foster parent is involved in helping achieve this goal.`
        },
        {
            key: 'caseWorker3',
            value: `After a set period of time, reunification may no longer be a viable option. In this instance, 
            the State will file for legal termination of parental rights, or the birth parents may surrender rights, 
            rather then going through a trial process. A child is not available for adoption until the parental rights of 
            the biological parents have been removed.`
        }
    ]
}

export const parentContent2 = {
    title: `Provide for the child's well-being`,
    wellBeing: [
        {
            key: 'wellBeing1',
            value: `If you are caring for a foster child in your home, you must ensure that their well-being is met,
             including emotional, medical & dental, and educational needs. Beyond regular medical & dental care, often
             times foster children will require therapy and treatment, due to the circumstances of their particular situation. 
             Because foster children have often been the subject of physical or sexual abuse, emotional abuse, or neglect, 
             they will often have special needs above and beyond other children.`
        },
        {
            key: 'wellBeing2',
            value: `Detailed information from a case worker is essential to ensure that all of the needs of the child
             are clearly understood and are met. Here is a brief list of the needs you may be required to meet for foster children:`
        },
    ],
    wellBeingSecList: [
        {
            key: 'secList1',
            label: `Medical needs`,
            value: `children who receive foster care payments are usually eligible for Medicaid from their specific state`
        },
        {
            key: 'secList2',
            label: `Food, Clothing, School Supplies`,
            value: `the state will usually reimburse foster parents for food, clothing and the normal supplies a child would
             need for school.The rates of reimbursement are based on any special needs the child may have.`
        },
        {
            key: 'secList3',
            label: `Sleeping `,
            value: `children may share bedrooms, but must have a separate bed. Children of the opposite sex may not share a
             room if above a certain age, which varies by state. Of course behavior concerns must be taken into account as 
             well when considering arrangements.Children are required to have 80 square feet of space in a room, or 40 square 
             feet of space when sharing a room.`
        },
        {
            key: 'secList4',
            label: `Other concerns `,
            value: `other circumstances may exists on a case by case basis, so it is important to have the needed details 
            from the child's caseworker.`
        }
    ]
}

export const parentContent3 = {
    title: `Make Time for Yourself to Prevent Burnout`,
    makeTime: [
        {
            key: 'makeTime1',
            value: `Any parent can face burnout, as they balance the needs of their children with their own. 
            This occurs with foster parents as well. If you take time for yourself and prevent burnout before it happens,
             you will be a better parent and foster parent.`
        },
        {
            key: 'makeTime2',
            value: `Babysitters are appropriate for foster parents, but check with your state for specific rules and 
            requirements for child care in a foster situation. Texas state law requires that babysitters be at least 16. 
            Respite, or short-term care to give a regular caregiver or foster parent a break, may also be an option.`
        },
        {
            key: 'makeTime3',
            value: `Check with your foster child's Texas Foster Care Service Case Manager for specific details.`
        }
    ]
}