export const aboutContent = [
    {
        key: `about`,
        title: `About Us`,
        desc: [
            {
                key: `desc1`,
                isLink: false,
                value: `Texas Foster Care and Adoption Services is a private non-profit child placing agency licensed 
                by the State of Texas. The organization was formed by like-minded individuals, dedicated to the mission 
                of making a real difference in the lives of children in the State of Texas.`
            },
            {
                key: `desc2`,
                isLink: false,
                value: `The number of foster children are growing more and more every year. Today more than ever, 
                we face the growing issue of needing more foster parents in Texas. Many people in the Texas region 
                either know current or former foster children or foster families.`
            },
            {
                key: `desc3`,
                isLink: false,
                value: `We are searching for people in the community to provide loving, nurturing and healing homes to
                 abused and neglected children in the state of Texas. Texas Foster Care covers the Austin Metro and San Antonio areas.`
            },
            {
                key: `desc4`,
                isLink: false,
                value: `We are members of Interagency Foster Care Coalition.`
            }
        ]
    },
    {
        key: `mission`,
        title: `Our Mission`,
        desc: [
            {
                key: `mission1`,
                isLink: false,
                value: `Texas Foster Care and Adoption Services is dedicated to foster children achieving their personal potentials.
                 We are committed to providing a safe and nuturing environment where quality care establishes the foundation for lifelong 
                 success. Texas Foster Care and Adoption Services, in partnership with diverse families and communities, strives to 
                 meet the needs of children in a professional, comprehensive, highly ethical and caring manner.`
            }
        ]
    },
    {
        key: `vision`,
        title: `Vision`,
        desc: [
            {
                key: `vision1`,
                isLink: false,
                value: `At Texas Foster Care our goal is to support foster children and families, with a specific focus on keeping 
                foster siblings together. Texas Foster Care works to ensure the needs of the child are met in an family-centered 
                program that brings families of origin, foster caregivers, agencies within the community, and our program staff together. 
                We provide quality care foster care services for neglected and abused children and parents.`
            }
        ],
        listHead: 'We focus on:',
        list: [
            {
                key:'visionListItem1',
                value: 'Learning the specific needs of the child and family'
            },
            {
                key:'visionListItem2',
                value: 'Working with foster and families of origin'
            },
            {
                key:'visionListItem3',
                value: 'Integration with community-based groups'
            },
            {
                key:'visionListItem4',
                value: 'Assessment and milestones'
            },
            {
                key:'visionListItem5',
                value: 'Cultural sensitivity and understanding'
            }
        ]
    },
    {
        key: `coreValues`,
        title: `Core Values`,
        listHead: 'Texas Foster Care Adoption Services Core Values:',
        list: [
            {
                key:'coreValuesListItem1',
                value: 'Keeping siblings together for child well-being and family continuity'
            },
            {
                key:'coreValuesListItem2',
                value: 'Engaging and supporting their families'
            },
            {
                key:'coreValuesListItem3',
                value: 'Developing and maintaining highly trained, committed staff, including foster caregivers'
            },
            {
                key:'coreValuesListItem4',
                value: 'Maintaining stable placements leading to permanent families'
            },
            {
                key:'coreValuesListItem5',
                value: 'Minimizing potential risk to children in our care'
            }
        ]
    }
]