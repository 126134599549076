import NotificationManager from "react-notifications/lib/NotificationManager";

import { reqInfo } from "../../util/fosterCareApi";
import { logger } from '../../logger';

export const REQ_INFO_SUBMIT = "REQ_INFO_SUBMIT";
export const REQ_INFO_SUBMIT_SUCCESS = "REQ_INFO_SUBMIT_SUCCESS";
export const REQ_INFO_SUBMIT_ERROR = "REQ_INFO_SUBMIT_ERROR";

export const reqInfoSubmitAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: REQ_INFO_SUBMIT, payload});
        reqInfo(payload).then( response => {
            (response && response.data && response.data.emailSent) &&
                dispatch({type: REQ_INFO_SUBMIT_SUCCESS, payload: response.data});
                NotificationManager.success('Email sent successfully!');
        }).catch( err => {
            let logOptions = {
                level: 'error',
                meta: payload
            };
            if(err.response && err.response.data && !err.response.data.isValid && err.response.data.errors){
                dispatch({type: REQ_INFO_SUBMIT_ERROR, payload: err.response.data.errors});
                NotificationManager.error(Object.values(err.response.data.errors));
            }else if(err.response && err.response.data && !err.response.data.emailSent && err.response.data.errors){
                logger.error('unable to send email due to user validation issues for Request Info action for the payload: ', logOptions);
                dispatch({type: REQ_INFO_SUBMIT_ERROR, payload: err.response.data.errors});
                NotificationManager.error(err.response.data.errors.form);
            }else {
                logger.error('unable to send email for Request Info action for the payload: ', logOptions);
                dispatch({type: REQ_INFO_SUBMIT_ERROR, payload: err.response});
                NotificationManager.error(err.message);
            }
        })
    }
}