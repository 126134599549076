import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import RequestInfoReducer from '../components/join/RequestInfoReducer';
import ContactUsReducer from '../components/contactUs/ContactUsReducer';
import CareersReducer from '../components/careers/CareersReducer';
import ApplyReducer from '../components/apply/ApplyReducer';

const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    requestInformation: RequestInfoReducer,
    contactUsInfo: ContactUsReducer,
    careers: CareersReducer,
    applyNow: ApplyReducer
});

export default rootReducer;