import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Box,  Card, Slide, CardActionArea, CardContent, Divider } from '@material-ui/core';
import { contactUsContent } from './ContactUsContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageSlider from '../../layout/ImageSlider';
import ContactUsForm from './ContactForm';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";
import { getIsSending } from './contactUsSelectors';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    sectionBox: {
        borderRadius: '20px',
        padding: '10px 0',
        boxShadow: '0px 4px 4px 2px rgb(90 78 80 / 55%)',
        margin: '20px 10px',
        backgroundColor: 'white'
    },
    card: {
        padding: '20px',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardsItems:{
        padding: '20px'
      },
    head: {
    color: '#22477E'
    }
  }));

const ContactUsView = () => {
    const classes = useStyles();
    const isSending = useSelector(getIsSending);

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={2000}>
                <Grid container justifyContent='space-around'>
                    {contactUsContent.map((card) => (
                        <Grid item xs={12} md={6}  className={classes.cardsItems} key={card.key}>
                            <Card className={classes.card} key={card.key}> 
                                <CardActionArea> 
                                    <CardContent>
                                        <Typography variant="h4" className={classes.cardTitle}>
                                            {card.title}
                                            <Divider/ >
                                        </Typography>
                                        
                                        <Grid container >
                                            {card.contact.map((item) => (
                                                <Grid item xs={12} md={6} key={item.key}>
                                                    <FontAwesomeIcon icon={item.icon}  size='2x'  className={classes.head}/>
                                                    <Typography gutterBottom variant="h5" className={classes.head} >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography variant="h6" >
                                                        {item.line1}
                                                    </Typography>
                                                    <Typography variant="h6" >
                                                        {item.line2}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                         </Grid>
                                    </CardContent>           
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Slide>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Message Us
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Box className={classes.sectionBox}>
                    <Grid container className={classes.panelContainer}>
                        <ContactUsForm />
                    </Grid>
                </Box>
            </Grow>
            {isSending && <Spinner />}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default ContactUsView;