import {SEND_APPLICATION, SEND_APPLICATION_SUCCESS, SEND_APPLICATION_ERROR} from './ApplyFormActions';

const defaultState = {
    formPayload: {
        fname1: '', lname1: '', mname1: '', fname2: '', lname2: '', mname2: '', homePhone: 0, cellPhone: 0, street: '', 
        city: '', state: 'Texas', zip: 0, county: '', adopt: '', care: '', aboutUs: '', ageCheck: 'no', hasSteadyIncome : 'no', 
        comfortCheck: 'no', assistMessage: '', relationshipMessage: '', experienceMessage: ''
    },
    isSending: false,
}

const ApplyReducer = (state = defaultState, action) => {
    switch(action.type){
        case SEND_APPLICATION:
            return {...state, formPayload: action.payload, isSending: true};
        case SEND_APPLICATION_SUCCESS:
        case SEND_APPLICATION_ERROR:
            return {state: defaultState, isSending: false};
        default : 
            return state;
    }
}
export default ApplyReducer;