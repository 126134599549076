export const linksContent = {
    desc: `Here are some helpful links to provide more information about foster care and about foster care in Texas:`,
    links: [
        {
            key: `link1`,
            label: `Texas Health and Human Services Commission`,
            value: `https://www.hhs.texas.gov/`
        },
        {
            key: `link2`,
            label: `Texas Department of Family and Protective Services (TDFPS)`,
            value: `http://www.dfps.state.tx.us/`
        },
        {
            key: `link3`,
            label: `Youth For Tomorrow`,
            value: `http://www.yft.org/`
        },
        {
            key: `link4`,
            label: `Foster Club for Kids`,
            value: `http://www.fosterclub.com/`
        },
        {
            key: `link5`,
            label: `Fostering Families Today Magazine`,
            value: `http://www.fosteringfamiliestoday.com/`
        },
        {
            key: `link6`,
            label: `National Foster Parent Assocation (NFPA)`,
            value: `http://www.nfpainc.org/`
        },
        {
            key: `link7`,
            label: `National Foster Care Month`,
            value: `http://www.fostercaremonth.org/`
        },
        {
            key: `link8`,
            label: `Child Welfare League of America (CWLA)`,
            value: `http://cwla.org/`
        },
        {
            key: `link9`,
            label: `Court Appointed Special Advocate (CASA)`,
            value: `http://www.casaforchildren.org/site/c.mtJSJ7MPIsE/b.5301295/k.BE9A/Home.htm`
        }
    ]
}