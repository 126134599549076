import { secret_key, captcha_api } from "./captchaKeyConsts";
import { EMAIL_TYPE } from "./emailConstants";
import { svcRequest } from "./requestHelper";

export const verifyRecaptcha = (token) => {
    const payload = {
        secret : secret_key,
        response : token
    }

    return svcRequest.post( captcha_api, payload);
}

export const submitJobAppl = (inputPayload) => {
    const formData = new FormData();
    formData.append('file', inputPayload.selectedFile[0]);
    formData.append('emailType',EMAIL_TYPE.jobApply );
    formData.append('name',inputPayload.name );
    formData.append('email',inputPayload.email );
    formData.append('phone',inputPayload.phone );

    const payloadConfig = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return svcRequest.post( "/email", formData, payloadConfig, {
        timeout: 30000
      });
}

export const sendApply = (inputPayload) => {
    const formData = new FormData();

    const adults = [
        {
            firstName: inputPayload.fname1,
            lastName: inputPayload.lname1,
            middleName: inputPayload.mname1,
            homePhone: inputPayload.homePhone,
            cellPhone: inputPayload.cellPhone,
           
        }, {
            firstName: inputPayload.fname2,
            lastName: inputPayload.lname2,
            middleName: inputPayload.mname2,
           
        }
    ]

    const areasOfIntr = [ inputPayload.adopt, inputPayload.care ]

    formData.append('emailType',EMAIL_TYPE.apply );
    formData.append('adults', JSON.stringify(adults));
    formData.append('street', inputPayload.street );
    formData.append('city', inputPayload.city );
    formData.append('state', inputPayload.state );
    formData.append('zip', inputPayload.zip );
    formData.append('county', inputPayload.county );
    formData.append('areasOfInterest', JSON.stringify(areasOfIntr));
    formData.append('aboutUs', inputPayload.aboutUs );
    formData.append('ageCheck', inputPayload.ageCheck );
    formData.append('hasSteadyIncome', inputPayload.hasSteadyIncome );
    formData.append('comfortCheck', inputPayload.comfortCheck );
    formData.append('assistMessage', inputPayload.assistMessage );
    formData.append('relationshipMessage', inputPayload.relationshipMessage );
    formData.append('experienceMessage', inputPayload.experienceMessage );

    const payloadConfig = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return svcRequest.post( "/email", formData, payloadConfig, {
        timeout: 30000
      });
}

export const reqInfo = (reqPayload) => {
    const formData = new FormData();
    formData.append('emailType',EMAIL_TYPE.reqInfo );
    formData.append('name', reqPayload.name );
    formData.append('email', reqPayload.email );
    formData.append('phoneNum',reqPayload.phoneNum !== 0 ? reqPayload.phoneNum : '' );
    formData.append('interest', reqPayload.subject ? reqPayload.subject : '' );
    formData.append('message', reqPayload.message ? reqPayload.message : '' );
    formData.append('sourceInfo', reqPayload.sourceInfo ? reqPayload.sourceInfo : '' );
    
    const payloadConfig = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return svcRequest.post( "/email", formData, payloadConfig, {
        timeout: 30000
      });
}   

export const contactUs = (contactPayload) => {
    const formData = new FormData();
    formData.append('emailType',EMAIL_TYPE.contact );
    formData.append('name', contactPayload.name );
    formData.append('email', contactPayload.email );
    formData.append('address', contactPayload.address );
    formData.append('location', contactPayload.location );
    formData.append('phoneNum',contactPayload.phoneNum !== 0 ? contactPayload.phoneNum : '' );
    formData.append('interest', contactPayload.interest );
    formData.append('message', contactPayload.message );
    formData.append('sourceInfo', contactPayload.sourceInfo );

    const payloadConfig = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return svcRequest.post( "/email", formData, payloadConfig, {
        timeout: 30000
      });
}   