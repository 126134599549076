import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText, Button } from '@material-ui/core';
import { articlesInfo } from './ArticlesInfoContent';
import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import { useHistory } from 'react-router';
import PayPalDonateView from '../../paypalDonate/PayPalDonateView';
import ImageSlider from '../../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    articlesTitle:{
          padding: '0 16px',
          color: '#d04e53',
          textAlign: 'left'
      },
    articlesInfoContainer: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '30px',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
    },
    learnMore: {
        padding: '10px 0',
        '& .MuiButtonBase-root': {
          color: 'white',
          cursor: 'pointer',
          backgroundColor: '#22477E'
        }
      },
  }));
  
const ArticlesInfoView = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleLearnMoreLink = (redirectRoute) => {
        history.push(redirectRoute);
      }

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Articles And Information" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Articles and Information
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Typography variant="body1"  className={classes.body}>
                    {articlesInfo.desc}
                </Typography>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.articlesInfoContainer}>
                {articlesInfo.contents.map((item) => (
                    <Fragment key={item.key}>
                        <Grid item className={classes.articlesTitle} xs={12}>
                            <Typography variant="h4" > 
                                {item.label}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="div" aria-label="svc content">
                                <ListItem component="div">
                                    <ListItemText primary={item.value} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} className={classes.learnMore}>
                            <Button size="small" color="inherit" onClick={() => handleLearnMoreLink(item.linkRoute)}>
                                Learn More
                            </Button>
                        </Grid>
                    </Fragment>
                ))}
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default ArticlesInfoView;
