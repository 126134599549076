import NotificationManager from "react-notifications/lib/NotificationManager";

import { verifyRecaptcha, submitJobAppl } from "../../util/fosterCareApi";
import { logger } from '../../logger';

export const APPLY_MODAL_STATE = "APPLY_MODAL_STATE";
export const CAPTCHA_VERIFY = "CAPTCHA_VERIFY";
export const CAPTCHA_VERIFY_SUCCESS = "CAPTCHA_VERIFY_SUCCESS";
export const CAPTCHA_VERIFY_ERROR = "CAPTCHA_VERIFY_ERROR";
export const SUBMIT_JOB_APPLICATION = "SUBMIT_JOB_APPLICATION";
export const SUBMIT_JOB_APPLICATION_SUCCESS = "SUBMIT_JOB_APPLICATION_SUCCESS";
export const SUBMIT_JOB_APPLICATION_ERROR = "SUBMIT_JOB_APPLICATION_ERROR";

export const  handleApplyModal = (flag) => {
    return (dispatch) => {
        dispatch({ type: APPLY_MODAL_STATE, payload: flag });
    }
}

export const captchaVerify = (token) => {
    return (dispatch) => {
        dispatch({ type: CAPTCHA_VERIFY});
        verifyRecaptcha(token).then( response => {
            dispatch({type: CAPTCHA_VERIFY_SUCCESS, payload: response.data});
        }).catch( err => {
            dispatch({type: CAPTCHA_VERIFY_ERROR});
        })
    }
}

export const submitJobApplication = (applyPayload) => {
    return (dispatch) => {
        dispatch({ type: SUBMIT_JOB_APPLICATION, payload: applyPayload});
        submitJobAppl(applyPayload).then( response => {
            (response && response.data && response.data.emailSent) &&
                dispatch({type: SUBMIT_JOB_APPLICATION_SUCCESS, payload: response.data});
                NotificationManager.success('Submitted successfully!');
        }).catch( err => {
            let logOptions = {
                level: 'error',
                meta: applyPayload
            };
            if(err.response.data && !err.response.data.isValid && err.response.data.errors){
                dispatch({type: SUBMIT_JOB_APPLICATION_ERROR, payload: err.response.data.errors});
                NotificationManager.error(Object.values(err.response.data.errors));
            }else if(err.response.data && !err.response.data.emailSent && err.response.data.errors){
                logger.error('unable to send email due to user validation issues for apply job action for the payload: ', logOptions);
                dispatch({type: SUBMIT_JOB_APPLICATION_ERROR, payload: err.response.data.errors});
                NotificationManager.error(err.response.data.errors.form);
            }else {
                logger.error('unable to send email for apply job action for the payload: ', logOptions);
                dispatch({type: SUBMIT_JOB_APPLICATION_ERROR, payload: err.response});
                NotificationManager.error(err.message);
            }
        })
    }
}
