import NotificationManager from "react-notifications/lib/NotificationManager";

import { sendApply } from "../../util/fosterCareApi";
import { logger } from '../../logger';

export const SEND_APPLICATION = "SEND_APPLICATION";
export const SEND_APPLICATION_SUCCESS = "SEND_APPLICATION_SUCCESS";
export const SEND_APPLICATION_ERROR = "SEND_APPLICATION_ERROR";

export const sendApplication = (formPayload) => {
    return (dispatch) => {
        dispatch({ type: SEND_APPLICATION, payload: formPayload});
        sendApply(formPayload).then( response => {
            (response && response.data && response.data.emailSent) &&
                dispatch({type: SEND_APPLICATION_SUCCESS, payload: response.data});
                NotificationManager.success('Email sent successfully!');
        }).catch( err => {
            let logOptions = {
                level: 'error',
                meta: formPayload
            };
            if(err.response && err.response.data && !err.response.data.isValid && err.response.data.errors){
                dispatch({type: SEND_APPLICATION_ERROR, payload: err.response.data.errors});
                NotificationManager.error(Object.values(err.response.data.errors));
            }else if(err.response && err.response.data && !err.response.data.emailSent && err.response.data.errors){
                logger.error('unable to send email due to user validation issues for apply action for the payload: ', logOptions);
                dispatch({type: SEND_APPLICATION_ERROR, payload: err.response.data.errors});
                NotificationManager.error(err.response.data.errors.form);
            }else {
                logger.error('unable to send email for apply action for the payload: ', logOptions);
                dispatch({type: SEND_APPLICATION_ERROR, payload: err.response});
                NotificationManager.error(err.message);
            }
        })
    }
}