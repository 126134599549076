import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Tabs, Tab,  Box} from '@material-ui/core';
import { applyContent } from './ApplyContent';
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ApplyForm from './ApplyForm';
import { useSelector } from 'react-redux';
import { getIsSending } from './ApplySelectors';
import Spinner from '../spinner/Spinner';
import ScrollUpButton from "react-scroll-up-button";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`apply-tabpanel-${index}`} 
            aria-labelledby={`apply-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}> {children} </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 0px 0px 1px rgb(90 78 80 / 55%)',
        backgroundColor: '#d8dfea0f',
    },
    desc: {
        fontWeight: '800',
        padding: `20px 0`
    },
    subTxt: {
        textAlign: 'left'
    },
    card: {
        padding: '20px',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardsItems:{
        padding: '20px'
      },
    head: {
    color: '#22477E'
    }
  }));

const a11yProps = (index) => {
    return {
        id: `apply-tab-${index}`,
        'aria-controls': `appply-tabpanel-${index}`,
    };
}

const ApplyView = () => {
    const classes = useStyles();
    const isSending = useSelector(getIsSending);

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Apply Now
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.desc}> {applyContent.desc} </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="apply tabs"
                className={classes.tabs}
            >
                 {applyContent.locations.map((tab, index) => (
                 <Tab label={tab.value} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <ApplyForm/>
                    </Grid>
                </Grow>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <ApplyForm/>
                    </Grid>
                </Grow>
            </TabPanel>
            {isSending && <Spinner />}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default ApplyView;