import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slide, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import gallery1 from "../../assets/img/gallery/gallery1.png";
import gallery2 from "../../assets/img/gallery/gallery2.jpg";
import gallery3 from "../../assets/img/gallery/gallery3.jpg";
import gallery4 from "../../assets/img/gallery/gallery4.png";
import gallery5 from "../../assets/img/gallery/gallery5.jpg";
import gallery6 from "../../assets/img/gallery/gallery6.jpeg";
import gallery7 from "../../assets/img/gallery/gallery7.jpg";
import gallery8 from "../../assets/img/gallery/gallery8.jpg";
import gallery9 from "../../assets/img/gallery/gallery9.jpg";
import gallery10 from "../../assets/img/gallery/gallery10.jpg";
import gallery11 from "../../assets/img/gallery/gallery11.jpg";
import gallery12 from "../../assets/img/gallery/gallery12.jpg";
import gallery13 from "../../assets/img/gallery/gallery13.jpg";
import gallery14 from "../../assets/img/gallery/gallery14.jpg";
import gallery15 from "../../assets/img/gallery/gallery15.jpg";
import gallery16 from "../../assets/img/gallery/gallery16.jpg";
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import { Skeleton } from '@material-ui/lab';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
    },
    title:{
        padding: '16px'
    },
    galleryCarousel:{
      margin: '10px 0'
    },
    gallery: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)'
    },
  }));

const GalleryView = () => {
    const classes = useStyles();
    
    let slides = [
       gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, gallery10, gallery11, gallery12,
       gallery13, gallery14, gallery15, gallery16
      ];

    return (
        <div className={classes.root} >
            <ImageSlider />
            <Grid container justifyContent='center' alignItems="center" className={classes.galleryCarousel}>
                <Grid item className={classes.title} xs={12}>
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1500}>
                        <Typography variant="h4" >
                            Gallery
                        </Typography>
                    </Slide>
                </Grid>
                <Carousel animation='slide' interval='5000'>
                {
                    slides.map( (item, i) => 
                        <Grid xs={12} item key={i} className={classes.gallery}>
                            {item ? <img src={item} height='600px' maxwidth='700px' alt={i}></img> :
                            <Skeleton variant="rectangular" width={400} height={600} animation="wave"/>}
                        </Grid> 
                    )
                }
                </Carousel>
            </Grid>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default GalleryView;
