import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Grid, Slide, Grow, List, ListItem, ListItemIcon, ListItemText,
   Card, CardActionArea, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router';
import { homeContent } from './HomeContent';
import AlbumIcon from '@material-ui/icons/Album';
import orphans from "../../assets/img/gallery/orphans.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '5px 0',
    },
    homeContainer: {
      color: '#1A355D',
      padding: '30px',
      boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)'
    },
    homeTitle: {
      fontWeight: '500'
    },
    desc: {
      padding: '25px'
    },
    fosterChildImg:{
      boxShadow: '9px -4px 7px 2px rgb(90 78 80 / 55%)'
    },
    cardsItems:{
      padding: '20px'
    },
    card: {
      padding: '20px',
      '& .MuiButtonBase-root': {
        cursor: 'default',
        backgroundColor: '#3567b20f'
      }
    },
    cardTitle: {
      color: '#d04e53'
    },
    media:{
      padding: '15px',
      color: '#22477E'
    },
    readMore: {
      justifyContent:'center',
      padding: '10px 0',
      '& .MuiButtonBase-root': {
        color: 'white',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            color: 'white',
            backgroundColor: '#485f81',
        }
      }
    },
    listIcon:{
      color: '#22477E',
      minWidth: '25px'
    },
    listLinks: {
      padding: 0,
    },
    secList: {
      padding: '0 20px'
    },
    secListRedirects:{
      textDecoration: 'none',
      color: '#22477E',
    }
  }));

const HomeView = () => {
    const classes = useStyles();
    // const dispatch = useDispatch();
    const history = useHistory();
    
    const handleReadMoreRedirect = (redirectRoute) => {
      history.push(redirectRoute);
    }

    return (
      <Box className={classes.root} >
        {/* carousel */}
        <ImageSlider />
        <Grid container justifyContent='center' alignItems="center" className={classes.homeContainer}>
          <Grow in={true} timeout={1500}>
            <Grid item xs={12} md={6} className={classes.desc}>
              <iframe src={`//www.youtube.com/embed/8C71EUxyRE4`}
                style={{border: `solid 1px #777`, width: '100%'}} height="300" scrolling="no" title={`vid1`} allowFullScreen></iframe>
            </Grid>
          </Grow>
          <Grow in={true} timeout={1500}>
            <Grid item xs={12} md={6} className={classes.desc}>
              <iframe src={`//www.youtube.com/embed/Gbq1D0nn9VI`}
                style={{border: `solid 1px #777`, width: '100%'}} height="300" scrolling="no" title={`vid2`} allowFullScreen></iframe>
            </Grid>
          </Grow>
        </Grid>
        <Grid container justifyContent='center' alignItems="center" className={classes.homeContainer}>
          <Grid item>
            <Grid container >
              <Grid item xs={12} md={6} className={classes.desc}>
                <Grow in={true} timeout={1500}>
                  <img src={orphans} alt="child care, orphanage, fostering, neglected, children" className={classes.fosterChildImg} height='350px' width='450px'/>
                </Grow>
              </Grid>
              <Grid item xs={12} md={6} className={classes.desc}>
                <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1500}>
                  <Typography variant="h5" className={classes.homeTitle}>
                    {homeContent.mainHead.value}
                  </Typography>
                </Slide>
                <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1500}>
                  <List component="div" aria-label="home content">
                    {homeContent.mainContent.map((item) => (
                      <ListItem component="div" className={classes.listLinks} key={item.key}>
                      <ListItemIcon>
                        <AlbumIcon />
                      </ListItemIcon>
                      <ListItemText primary={item.value} />
                    </ListItem>
                    ))}
                  </List>
                </Slide>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              {homeContent.mainReadCards.map((card) => (
                <Grid item xs={12} md={4}  className={classes.cardsItems} key={card.key}>
                  <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={2000}>
                    <Card className={classes.card} key={card.key}> 
                      <CardActionArea> 
                        <CardMedia className={classes.media} title={card.title} >
                          <FontAwesomeIcon icon={card.cardIcon} size='9x'/>
                        </CardMedia>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                            {card.title}
                          </Typography>
                          <List component="div" aria-label="card-content">
                            {card.cardContentList.map((listItem) => (
                              <ListItem component="div" className={classes.listLinks} key={listItem.key}>
                                <ListItemIcon className={classes.listIcon}>
                                  <FontAwesomeIcon icon={listItem.icon}  size='2x'/>
                                </ListItemIcon>
                                <ListItemText primary={listItem.value} />
                              </ListItem>
                            ))}
                          </List>
                          <List component="div" aria-label="card-secondary-content" className={classes.secList}>
                          {card.cardContentSecList?.map((secListItem) => (
                            <ListItem component="div" className={classes.listLinks} key={secListItem.key}>
                              <ListItemIcon className={classes.listIcon}>
                                <FontAwesomeIcon icon={secListItem.icon}/>
                              </ListItemIcon>
                              {secListItem.isRedirect ? 
                                <Link to={`/${secListItem.redirectLink}`} className={classes.secListRedirects}>
                                  <ListItemText primary={secListItem.value} />
                                </Link> 
                                : <ListItemText primary={secListItem.value} />}
                            </ListItem>
                          ))}
                          </List>
                        </CardContent>           
                      </CardActionArea>
                      <CardActions className={classes.readMore}>
                        <Button size="small" color="inherit" title="Click to know more.." 
                          onClick={() => handleReadMoreRedirect(card.readMoreLink)}>
                          Read More
                        </Button>
                      </CardActions>
                    </Card>
                  </Slide>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <PayPalDonateView />
        <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
      </Box>
    );
}
export default HomeView;