export const helpKidsContent = {
    desc: `Helping children in foster care can include more than being a foster parent. Here are a few good
     suggestions on how you can help families and children in foster care.`,
    contents: [
        {
            key: `childHome`,
            label: `Volunteer at a children’s home`,
            value: `Children’s homes can be the first location for foster children, or even their home in 
            between foster families. Children’s homes are always in need of volunteers to help with many 
            different tasks. Sorting and organizing donations, reading or playing games with the children, 
            or even getting involved in outdoor activities are all opportunities. Contact your local children’s 
            home and find out how you can help.`,
        },
        {
            key: `helpDrive`,
            label: `Help drive a kid around`,
            value: `foster agencies need help taking children to their appointments and meetings, including medical
             and dental visits, visiting their birth families, or even to a foster home. Some trips can be over long 
             distances, but many need help right in your area. You can call your local agency to see if they have this need.`
        },
        {
            key: `bigBroSis`,
            label: `Be a Big Brother or Sister`,
            value: `spending a few hours a week with a child can make a huge different in their life. It can be fun to 
            work or play with a child at their hobby or introduce them to something new. Though the Big Brother/Big Sister 
            program includes non-foster children as well, foster children are a big part of their program. Find out more at `,
            extLink: `Big Brothers Big Sisters of South Texas`
        },
        {
            key: `jobHelp`,
            label: `Help with a Job`,
            value: `Many young people in a foster care program have a hard time finding part time work. Having a job can give 
            a foster kid a sense of worth and help them feel empowered. Consider helping a foster child with a first job to gain 
            valuable work experience.`
        },
        {
            key: `donate`,
            label: `Donate`,
            value: `There are always plenty of items needed by a foster care agency or children’s home. Shoes, clothes, toys, 
            and school supplies all help to make the environment better for foster kids. Since many children don’t have luggage 
            or suitcases to carry their belongings from home to home, these are always a good idea. Only donate items that are in 
            good or like-new condition. Some agencies only allow new items, so check first before making your donations.`
        },
        {
            key: `respiteCare`,
            label: `Help with Respite Care`,
            value: `Respite care is a way to provide relief to other foster families or to help with emergency care of foster children.
             Often this involves having a foster child in your home for a limited period of time from a few days to a month.`
        },
        {
            key: `help`,
            value: `Children in foster care need your help! Call your local children’s home or foster care agency to see what you can do.`
        }
    ]
}