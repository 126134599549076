import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Grid, Grow, Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';
import { faqContent } from './FaqContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../paypalDonate/PayPalDonateView';
import ImageSlider from '../../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    typeRoot: {
        padding: '10px 0',
        textAlign: 'left'
    },
    ques:{
        padding: '0 16px',
        color: '#d04e53',
        textAlign: 'left'
    },
    accordionBody: {
        backgroundColor: '#738db33d',
        padding: '40px'
    }
  }));

const FaqView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                    <BreadcrumbMenu currComp="FAQs" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Frequently Asked Questions
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            
            {faqContent.map((item, index) => (
                <Accordion key={item.key}>
                    <Grow in={true} timeout={1500}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={index + item.key} id={index + item.key} >
                            <Typography variant="h4" className={classes.ques}>{item.question}</Typography>
                        </AccordionSummary>
                    </Grow>
                    <AccordionDetails className={classes.accordionBody}>
                        <Grid container direction='column' >
                            {item.answer.map((ans) => (
                                <Grid item key={ans.key}>
                                    {ans.isLink ? 
                                        <Link to={`/${ans?.linkTo}`}>
                                            <Typography variant="body1" className={classes.typeRoot} >
                                                {ans.value}
                                            </Typography>
                                        </Link> : 
                                    <Typography variant="body1" className={classes.typeRoot} >
                                        {ans.value}
                                    </Typography> }
                                </Grid>
                            ))} 
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default FaqView;