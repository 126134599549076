import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, List, ListItem, ListItemText } from '@material-ui/core';
import { adoptContent } from './AdoptionContent';
import BreadcrumbMenu from '../../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    adoptTitle:{
        padding: '0 16px',
        color: '#d04e53'
      },
    adoptContainer: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '10px 0',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
    }
  }));
  
const Adoption = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Adoption" secParentComp= "Home" parentComp="Articles And Information"
                        secParentRoute="/" parentCompRoute="/articlesInfo"/>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.adoptContainer}>
                    <Grid item className={classes.adoptTitle} xs={12}>
                            <Typography variant="h4" >
                                {adoptContent.title}
                            </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="div" aria-label="svc content">
                            {adoptContent.contents.map((content) => (
                                <ListItem component="div" key={content.key}>
                                    <ListItemText primary={content.value} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default Adoption;
