import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Slide, Grow, List, ListItem, ListItemText, Tabs, Tab, Card, CardActionArea, CardContent, Divider } from '@material-ui/core';
import { servicesContent, tab1Content, tab2Content, tab3Content, tab4Content, tab5Content } from './ServicesContent';
import ImageSlider from '../../layout/ImageSlider';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`services-tabpanel-${index}`} 
            aria-labelledby={`services-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>
                     {children}
                </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    svcTitle:{
        padding: '0 16px'
    },
    svcContainer: {
        color: '#1A355D',
        padding: '10px 0',
      },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 4px 11px 1px rgb(90 78 80 / 55%)',
        backgroundColor: 'white',
    },
    panelContainer: {
        color: '#1A355D',
    },
    card: {
        padding: '20px',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardHead: {
        color: '#22477E',
        padding: '7px 0'
    }
  }));

const a11yProps = (index) => {
    return {
        id: `services-tab-${index}`,
        'aria-controls': `services-tabpanel-${index}`,
    };
}

const ServicesView = () => {
    const classes = useStyles();
    // const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
   
    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grid container className={classes.svcContainer}>
                <Grid item className={classes.svcTitle} xs={12}>
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1500}>
                        <Typography variant="h4" >
                            Our Services
                        </Typography>
                    </Slide>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={1500}>
                            <Grid item xs={12} md={4}>
                                <Card className={classes.card}> 
                                    <CardActionArea> 
                                        <CardContent>
                                            <Typography variant="h4" className={classes.cardTitle}>
                                                Call Us
                                                <Divider/ >
                                            </Typography>
                                            <Grid container >
                                                <Grid item xs={12} className={classes.cardHead}>
                                                    <Typography variant="h6">
                                                        San Antonio,TX 78228
                                                    </Typography>
                                                    <Typography variant="h6" >
                                                        Tel: 210-332-9171
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.cardHead}>
                                                    <Typography variant="h6" >
                                                        Austin, TX 78704
                                                    </Typography>
                                                    <Typography variant="h6" >
                                                        Tel:512-394-6854
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>           
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Slide>
                        <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1500}>
                            <Grid item xs={12} md={8}>
                                <List component="div" aria-label="svc content">
                                    {servicesContent.svcContent.map((content) => (
                                        <ListItem component="div" key={content.key}>
                                            <ListItemText primary={content.value} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Slide>
                    </Grid>
                </Grid>
            </Grid>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="services tabs"
                className={classes.tabs}
            >
                 {servicesContent.serviceTabs.map((tab, index) => (
                 <Tab label={tab.label} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                            <List component="div" aria-label="svc content">
                                {tab1Content.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                            <List component="div" aria-label="svc content">
                                {tab2Content.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                            <List component="div" aria-label="svc content">
                                {tab3Content.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                                <ListItemText primary="More details on Permanency Care Assistance (PCA) in Texas can be found " />
                                <ListItemText><a href="http://www.dfps.state.tx.us/I_Am/kinship.asp" target="_blank" 
                                rel="noreferrer" >here</a> </ListItemText>
                            </List>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                            <List component="div" aria-label="svc content">
                                {tab4Content.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={4} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                            <List component="div" aria-label="svc content">
                                {tab5Content.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    );
}

export default ServicesView;