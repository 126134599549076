import './App.css';
import 'react-notifications/lib/notifications.css';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme  } from '@material-ui/core/styles';
import { NotificationContainer } from 'react-notifications';
import HeaderMenu from './layout/HeaderMenu';
import Footer from './layout/Footer';
import ServicesView from './components/services/ServicesView';
import AboutView from './components/about/AboutView';
import HomeView from './components/home/HomeView';
import FosterParentView from './components/fosterParent/FosterParentView';
import DonationView from './components/getInvolveCard/donation/DonationView';
import VolunteerView from './components/getInvolveCard/volunteer/VolunteerView';
import ArticlesInfoView from './components/getInvolveCard/articlesInfo/ArticlesInfoView';
import Adoption from './components/getInvolveCard/articlesInfo/adoption/Adoption';
import HelpFosterKids from './components/getInvolveCard/articlesInfo/helpFosterKids/HelpFosterKids';
import HomeStudy from './components/getInvolveCard/articlesInfo/homeStudy/HomeStudy';
import FaqView from './components/getInvolveCard/faqs/FaqView';
import HelpfulLinksView from './components/getInvolveCard/helpfulLinks/HelpfulLinksView';
import JoinView from './components/join/JoinView';
import ContactUsView from './components/contactUs/ContactUsView';
import GalleryView from './components/gallery/GalleryView';
import ApplyView from './components/apply/ApplyView';
import EventsView from './components/events/EventsView';
import CareersView from './components/careers/CareersView';

const App = () => {
  const appTheme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    }
  });
  
  appTheme.typography.h4 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1.675rem',
    fontWeight: '400',
    [appTheme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.7rem',
    },
    [appTheme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.5rem',
    },
  };

  appTheme.typography.h6 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1.1rem',
    fontWeight: '500',
    [appTheme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    }
  };

  appTheme.typography.subtitle1 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1rem',
    fontWeight: '400',
    [appTheme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
      fontWeight: '400',
    }
  };

  return (
    <ThemeProvider theme={appTheme}>
      <div className="App">
        <HeaderMenu />
        <div style={{minHeight: '1080px'}}>
          <Switch>
            <Route path="/" name="Home" exact component={HomeView}/>
            <Route path="/about" name="AboutUs" exact component={AboutView}/>
            <Route path="/services" name="Services" exact component={ServicesView}/>
            <Route path="/contactUs" name="Contact Us" exact component={ContactUsView}/>
            <Route path="/gallery" name="Gallery" exact component={GalleryView}/>
            <Route path="/events" name="Events" exact component={EventsView}/>
            <Route path="/apply" name="Apply Now" exact component={ApplyView}/>
            <Route path="/careers" name="Careers" exact component={CareersView}/>
            <Route path="/becomeFosterParent" name="FosterParent" exact component={FosterParentView} />
            <Route path="/donation" name="Donation" exact component={DonationView} />
            <Route path="/volunteer" name="Volunteer" exact component={VolunteerView} />
            <Route path="/articlesInfo" name="Articles And Information" exact component={ArticlesInfoView} />
            <Route path="/adoption" name="Adoption" exact component={Adoption} />
            <Route path="/homeStudy" name="Home Study" exact component={HomeStudy} />
            <Route path="/helpFosterKids" name="Help Foster Kids" exact component={HelpFosterKids} />
            <Route path="/faq" name="Frequently Asked Questions" exact component={FaqView} />
            <Route path="/helpLinks" name="Helpful Links" exact component={HelpfulLinksView} />
            <Route path="/join" name="Join Us" exact component={JoinView} />
          </Switch>
        </div>
        <Footer />
      </div>
      < NotificationContainer />
    </ThemeProvider>
  );
}

export default App;
