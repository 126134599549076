import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Tabs, Tab, List, ListItem, ListItemText, ListItemIcon, Box} from '@material-ui/core';
import { joinUsContent } from './JoinContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbMenu from '../../layout/BreadcrumbMenu';
import RequestInfoForm from './RequestInfoForm';
import PayPalDonateView from '../paypalDonate/PayPalDonateView';
import ScrollUpButton from "react-scroll-up-button";
import { useSelector } from 'react-redux';
import { getIsRequesting } from './RequestInfoSelectors';
import Spinner from '../spinner/Spinner';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`joinUs-tabpanel-${index}`} 
            aria-labelledby={`joinUs-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 4px 11px 1px rgb(90 78 80 / 55%)',
        backgroundColor: 'white',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    subTxt: {
        textAlign: 'left'
    },
    link: {
        minWidth: '20px',
        color: '#1A355D'
    }
  }));

const a11yProps = (index) => {
    return {
        id: `joinUs-tab-${index}`,
        'aria-controls': `joinUs-tabpanel-${index}`,
    };
}

const JoinView = () => {
    const classes = useStyles();
    const isReq = useSelector(getIsRequesting);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                    <BreadcrumbMenu currComp="Join Us" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                </Grid>
            </Grow>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="join us tabs"
                className={classes.tabs}
            >
                 {joinUsContent.map((tab, index) => (
                 <Tab label={tab.title} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <Grid item xs={12}>
                            <Typography className={classes.desc}> {joinUsContent[0].desc1} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="div" aria-label="joinUs content">
                                {joinUsContent[0].listItems.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemIcon className={classes.link}>
                                            <FontAwesomeIcon icon={faCaretRight} />
                                        </ListItemIcon>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.desc}> {joinUsContent[0].desc2} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="div" aria-label="joinUs content">
                                {joinUsContent[0].address.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Grow>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <Grid item xs={12}>
                            <Typography className={classes.desc}> {joinUsContent[1].desc1} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.subTxt}> {joinUsContent[1].subText1} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.subTxt}> {joinUsContent[1].subText2} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.desc}> {joinUsContent[1].desc2} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="div" aria-label="joinUs content">
                                {joinUsContent[1].listItems.map((p) => (
                                    <ListItem component="div" key={p.key}>
                                         <ListItemIcon className={classes.link}>
                                            <FontAwesomeIcon icon={faHandPointRight} />
                                        </ListItemIcon>
                                        <ListItemText primary={p.value} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.subTxt}> {joinUsContent[1].subText3} </Typography>
                        </Grid>
                    </Grid>
                </Grow>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabsPanel}>
                <RequestInfoForm />
            </TabPanel>
            {isReq && <Spinner />}
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default JoinView;