import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
  }));

const BreadcrumbMenu = (props) => {
    const { currComp, parentComp, parentCompRoute, secParentComp, secParentRoute } = props;
    const classes = useStyles();

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.body}>
            {secParentComp &&  <Link to={secParentRoute}> {secParentComp} </Link>}
            <Link to={parentCompRoute}> {parentComp} </Link>   
            <Typography color="textPrimary">{currComp}</Typography>
        </Breadcrumbs>
    )
}

export default BreadcrumbMenu;