export const faqContent = [
    {
        key: `faq1`,
        question: `What is Foster Care?`,
        answer: [
            {
                key: `faq1ans1`,
                isLink: false,
                value: `Every year, the State of Texas removes thousands of children from their homes due to severe neglect,
                 physical abuse, emotional abuse, and/or sexual abuse. Courts remove the children to protect them and 
                 authorize the Department of Family & Protective Services (DFPS – or Child Protective Services), to place 
                 the children with private foster agencies such as Texas Foster Care. Texas Foster Care has a contract with 
                 DFPS to provide safe, nurturing foster homes with the goal of promoting growth and healing in these children.`
            },
            {
                key: `faq1ans2`,
                isLink: false,
                value: `Once placed in foster homes, the State (DFPS) continues to assess what is in the best interest of 
                the child. Children can be returned to their biological mother or father once the family proves they can provide 
                appropriate and nurturing care. If a responsible and caring adult is found within the extended biological family
                 children may be placed with relatives. If these two situations are not available, a child may remain in the foster 
                 care system. A child becomes adoptable if the courts terminate the biological parental rights.`
            }
           
        ]
    },
    {
        key: `faq2`,
        question: `What types of children are placed in Foster Homes?`,
        answer: [
            {
                key: `faq2ans1`,
                isLink: false,
                value: `Texas Foster Care provides foster homes for boys and girls ages 0-18 all over San Antonio, 
                Austin and Central and South Texas. Most of the children and young people placed in foster care 
                were removed from their biological families due to physical, emotional, or sexual abuse, or because
                 of neglect.`
            },
            {
                key: `faq2ans2`,
                isLink: false,
                value: `There are unique challenges to fostering a child that are different than raising your own 
                children. Children who have gone through the horror of physical abuse, sexual abuse, neglect, exposure 
                to drugs, and removal from their biological family require patience, unconditional love and acceptance.
                 They could have a difficult time controlling their emotions. Many struggle in school and have trust 
                 issues with adults. Our staff at Texas Foster Care are highly skilled in providing the support and training
                  needed to help foster families overcome these issues. Fostering requires dedication to the child and process,
                   open communication with staff, and committing to work with the child on a daily basis when issues develop.`
            }
           
        ]
    },
    {
        key: `faq3`,
        question: `Is adoption possible?`,
        answer: [
            {
                key: `faq3ans1`,
                isLink: false,
                value: `It is common for foster parents to adopt a foster child that has been placed in their home. After a child 
                is adopted, some families also continue to provide foster care to other children.`
            },
            {
                key: `faq3ans2`,
                isLink: false,
                value: `Foster children adopted by their foster parents are generally more successful than those adopted by another 
                family who did not provide foster care. Texas Foster Care can help with the process if you decide to adopt a foster 
                child placed in your home.`
            }
           
        ]
    },
    {
        key: `faq4`,
        question: `What do I do next if I want to be a Foster Parent?`,
        answer: [
            {
                key: `faq4ans1`,
                isLink: true,
                linkTo: 'join',
                value: `Contact Texas Foster Care to receive more information. `
            },
            {
                key: `faq4ans2`,
                isLink: false,
                value: `We will mail you an application and further details on the foster care process. If you have any questions 
                about the application, our staff is here to help.`
            },
            {
                key: `faq4ans3`,
                isLink: false,
                value: `Once the application is submitted, Texas Foster Care will contact you to schedule the additional interviews 
                and training By becoming a foster parent with Texas Foster Care, you will change a child’s life. Nothing is more important
                 to a child than a safe and loving home!`
            }
           
        ]
    },
    {
        key: `faq5`,
        question: `How long does it take to become a Foster Parent?`,
        answer: [
            {
                key: `faq5ans1`,
                isLink: false,
                value: `The well-being and safety of children is very important, so becoming a foster parent takes some time. Meetings
                 and interviews occur along with a review of your home environment. Texas Foster Care will provide the foster family 
                 with special training focused on helping traumatized children who have been subject to abuses. A lot of the training 
                 will occur before a child is placed in your home. The entire process usually takes 2-3 months.`
            }
           
        ]
    },
    {
        key: `faq6`,
        question: `What about paying for the child's food, insurance, clothing, education?`,
        answer: [
            {
                key: `faq6ans1`,
                isLink: false,
                value: `Texas Foster Care reimburses foster parents for most costs related to raising a child, with the reimbursement 
                based on the specific emotional and psychological needs of the child. The more needs a child has, the higher the reimbursement 
                the foster parent(s) will receive.`
            },
            {
                key: `faq6ans2`,
                isLink: false,
                value: `Children placed in foster homes are assigned a Level of Care by DFPS, which equates to a daily reimbursement 
                rate for that child. These levels, from lowest to highest, are: Basic ($23.15), Moderate ($41.77), and Specialized ($54.85). 
                The lower the Level of Care (LOC), the more stable the child. The higher the level of care, the more needs the child has, 
                and so more dollars are provided to cover those additional needs`
            },
            {
                key: `faq6ans3`,
                isLink: false,
                value: `Level of Care is calculated daily, thus foster families are reimbursed according to the number of days a child lives 
                in the home and payments are received twice a month`
            },
            {
                key: `faq6ans4`,
                isLink: false,
                value: `Medical and dental expenses are covered by Medicaid since most children placed in foster care qualify for this program. 
                Education is provided through the public school system and foster children are also provided a free college education.`
            }
           
        ]
    },
    {
        key: `faq7`,
        question: `What kind of support do I get as a Foster Parent?`,
        answer: [
            {
                key: `faq7ans1`,
                isLink: false,
                value: `You will be notified when Texas Foster Care staff finds a child they think will be a good match for your family. You will 
                receive all the information that Texas Foster Care has about the child. Foster parents make the final decision if they think the 
                child will be a good fit in their home`
            },
            {
                key: `faq7ans2`,
                isLink: false,
                value: `When a child is placed in your home, case managers will be assigned to your family and will work directly with you to help 
                meet the needs of the children. Texas Foster Care also has specialized staff members available to help support your family. This may
                 include licensed counselors, social workers, psychologists, and psychiatrists. In case of emergency, Texas Foster Care Case 
                 Managers are available 24 hours a day, 7 days a week.`
            },
            {
                key: `faq7ans3`,
                isLink: false,
                value: `In addition, foster parents are offered training opportunities throughout the year. This training – along with other 
                activities – provides different options for foster families to meet and develop support networks among other families going through
                 the same challenges. Often foster families become friends and rely on each other for respite care and other supportive needs.`
            }
           
        ]
    },
]