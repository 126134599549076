import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Grid, Grow, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { volunteerOpp } from './VolunteerContent';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import PayPalDonateView from '../../paypalDonate/PayPalDonateView';
import ImageSlider from '../../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
      },
    title:{
        padding: '0 16px 10px 16px'
    },
    body: {
        color: '#1A355D',
        padding: '10px 0',
    },
    volunteerTitle:{
        padding: '0 16px',
        color: '#d04e53'
      },
    volunteerContainer: {
        backgroundColor: 'white',
        color: '#1A355D',
        padding: '10px 0',
        boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
        marginBottom: '20px'
    },
    listLinks: {
        padding: '0 30px',
        alignItems: 'center'
    },
    caret: {
        minWidth: '20px',
        color: '#1A355D'
    }
  }));
  
const VolunteerView = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={classes.root}>
            <ImageSlider />
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <BreadcrumbMenu currComp="Volunteer" parentComp="Home" parentCompRoute="/"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Volunteer
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.volunteerContainer}>
                    {volunteerOpp.map((item) => (
                        <Fragment key={item.key}>
                            <Grid item className={classes.volunteerTitle} xs={12}>
                                <Typography variant="h4" >
                                    {item.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List component="div" aria-label="volunteer content">
                                    {item.contents.map((content) => (
                                        content.label ? 
                                            content.value.map((item) => (
                                            <ListItem component="div" key={item.key} className={classes.listLinks}>
                                                <ListItemIcon className={classes.caret}>
                                                    <FontAwesomeIcon icon={faCaretRight} size='2x'/>
                                                </ListItemIcon>
                                                <ListItemText primary={item.value} />
                                            </ListItem>
                                             )) 
                                            : 
                                            <ListItem component="div" key={content.key} >
                                                {content.key === "content3" ? 
                                                <>
                                                    <ListItemText primary={content.value} />
                                                    <Link to="/join"><ListItemText primary="Foster Caregivers page. "/> </Link>
                                                </> :
                                                <ListItemText primary={content.value} /> }
                                            </ListItem>
                                    ))}
                                </List>
                            </Grid>
                       </Fragment>
                    ))}
                   
                </Grid>
            </Grow>
            <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default VolunteerView;
